import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dropzone from 'react-dropzone';
import request from 'superagent';

import LoadingComponent from './../components/loading';

import EneFirestore from './../../libs/ene/firestore';

const UPLOAD_URL = 'https://us-central1-autoroble-bfc09.cloudfunctions.net/uploadImage?token=';


class ImageVehicleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.eneFirestore = new EneFirestore();

    this.state = {
      loading: true,
      doneToUpload: false,
      uploadedFileCloudinaryUrl: '',
      group: {},
      model: {}
    };

    this.token = ''
  }

  componentWillMount() {
    this.eneFirestore.getIdToken().then((token) => {
      this.token = token
    }).catch((error) => console.log(error))
  }

  componentDidMount() {
    const { brand, group, model } = this.props.match.params;
    if (model) {
      this.eneFirestore.getDocumentWhere(['models'], [
        ['brand', '==', brand],
        ['group', '==', group],
        ['code', '==', model],
      ]).then((res) => {
        if (!res.empty) {
          const rawData = res.docs[0] 
          const dataModel = rawData.data()
          dataModel.id = rawData.id
          this.setState({
            doneToUpload: true,
            loading: false,
            group: null,
            model: dataModel,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.eneFirestore.getDocumentWhere(['groups'], [
        ['brand', '==', brand],
        ['code', '==', group],
      ]).then((res) => {
        if (!res.empty) {
          const rawData = res.docs[0]
          const dataModel = rawData.data()
          dataModel.id = rawData.id 
          this.setState({
            doneToUpload: true,
            loading: false,
            group: dataModel,
            model: null,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    }
  }

  onImageDrop = (files) => {
    this.setState({
      uploadedFile: files[0],
    });

    this.handleUpload(files[0]);
  }


  handleUpload = (file) => {
    const { group, model } = this.state;
    const pathDocument = model ? ['models', model.id] : ['groups', group.id]
    const id = model ? `${model.brand}_${model.group}_${model.code}` : `${group.brand}_${group.code}`;
    const upload = request.post(UPLOAD_URL + this.token)
      .field('image', file)
      .field('public_id', id);

    upload.end((err, response) => {
      if (response.body.secure_url !== '') {
        this.eneFirestore.updateDocument(pathDocument, {vi: response.body.version}).then(() => {
          this.setState({
            uploadedFileCloudinaryUrl: response.body.secure_url,
          });
        })
      }
    });
  }

  render() {
    const {
      doneToUpload, loading,
      uploadedFile, uploadedFileCloudinaryUrl,
      group, model,
    } = this.state;

    if (loading) {
      return <LoadingComponent message="Cargando..." />;
    }

    if (doneToUpload) {
      return (
        <div>
          <div className="upload-image-vehicles">
            <h4>{model ? model.name : group.name}</h4>
            <Dropzone className="area-drop" multiple={false} accept="image/*" onDrop={this.onImageDrop}>
              {({getRootProps, getInputProps}) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Arrastra la imagen o click para seleccionar.</p>
                </div>
              )}
            </Dropzone>
          </div>
          {
            uploadedFileCloudinaryUrl &&
            <div>
              <img src={uploadedFileCloudinaryUrl} alt={uploadedFile.name} />
              <p>{uploadedFile.name}</p>
            </div>
          }
        </div>
      );
    }
    return (
      <div>
        <div className="upload-image-vehicles">
          <p>No se puede Subir una imagen, verifique que haya seleccionado correctamente</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageVehicleManagement);
