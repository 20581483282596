import React from 'react';

import Paper from '@material-ui/core/Paper';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Switch from '@material-ui/core/Switch';

import LoadingComponent from '../components/loading';

import EneFirestore from '../../libs/ene/firestore';

export default class View extends React.Component {
  constructor(props) {
    super(props);
    const { key } = props.match.params;
    this.state = {
      keyMaintenance: key,
      brands: [],
      groups: [],
      models: [],
      brandSelected: '',
      groupSelected: '',
      modelSelected: null,
      modelsMaintenance: [],
      messageLoading: 'Cargando...',
      loading: true,
      loadingCol2: false,
      loadingCol3: false,
      loadingCol4: false,
    };
    this.eneFirestore = new EneFirestore();
  }

  componentDidMount() {
    this.fetchMarks();
    this.fetchModelMaintenance();
  }

  onChange = name => (evt) => {
    this.setState({
      [name]: evt.target.value,
    });
  }

  fetchModelMaintenance = () => {
    const { keyMaintenance } = this.state;
    this.eneFirestore.getReference(['maintenances', keyMaintenance, 'models']).get()
      .then((res) => {
        if (!res.empty) {
          const modelsMaintenance = [];
          res.forEach((doc) => {
            const mm = doc.data();
            modelsMaintenance[doc.id] = mm;
          });
          this.setState({ modelsMaintenance });
        }
      });
  }

  fetchMarks = () => {
    this.eneFirestore.getReference(['brands']).where('show', '==', true).orderBy('order').get()
      .then((res) => {
        if (!res.empty) {
          const docs = [];
          res.forEach((doc) => {
            docs.push(doc.data());
          });
          const itemSelected = docs[0];
          this.setState({ brands: docs, brandSelected: itemSelected.code });
          this.fetchGroups(itemSelected.code);
        }
      });
  }

  fetchGroups = (brand) => {
    this.eneFirestore.getDocumentWhere(['groups'], [['brand', '==', brand], ['show', '==', true]]).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          items.push({
            brand: it.brand,
            code: it.code,
            name: it.name,
            show: it.show !== undefined ? it.show : false,
          });
        });
        this.setState({
          groups: items,
          brandSelected: brand,
          groupSelected: items[0].code,
          loadingCol2: false,
        });
        this.fetchModels(brand, items[0].code);
      } else {
        this.setState({
          groups: [],
          brandSelected: brand,
          loadingCol2: false,
        });
      }
    });
    this.setState({
      loadingCol2: true,
    });
  }

  fetchModels = (brand, group) => {
    this.eneFirestore.getDocumentWhere(['models'], [
      ['brand', '==', brand],
      ['group', '==', group],
      ['show', '==', true],
    ]).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          const years = it.year ? it.year.replace(/\s/g, '').split(',') : [];
          const idYears = [];
          years.forEach(item => idYears.push({ id: item.split(':')[0], year: item.split(':')[1] }));
          items.push({
            brand: it.brand,
            group: it.group,
            code: it.code,
            name: it.name,
            show: it.show !== undefined ? it.show : false,
            idYears,
          });
        });
        this.setState({
          models: items,
          brandSelected: brand,
          groupSelected: group,
          modelSelected: items[0],
          loading: false,
          loadingCol3: false,
          loadingCol4: false,
        });
      } else {
        this.setState({
          models: [],
          brandSelected: brand,
          groupSelected: group,
          loading: false,
          loadingCol3: false,
          loadingCol4: false,
        });
      }
    });

    this.setState({
      loadingCol3: true,
      loadingCol4: true,
    });
  }

  handleSwitchModelYear = (model, value) => {
    const { keyMaintenance } = this.state;
    this.eneFirestore.updateDocument(['maintenances', keyMaintenance, 'models', model], { show: value })
      .then(() => {
        this.fetchModelMaintenance();
      });
  }

  selectModel = modelSelected => () => {
    this.setState({ modelSelected });
  }

  selectYear = idYearSelected => () => {
    const { keyMaintenance } = this.state;
    this.props.history.push(`${keyMaintenance}/${idYearSelected}`);
  }

  render() {
    const {
      loading, loadingCol2, loadingCol3, loadingCol4, messageLoading,
      brands, brandSelected, groups, groupSelected, modelSelected, models,
      modelsMaintenance,
    } = this.state;

    if (loading) {
      return <LoadingComponent message={messageLoading} />;
    }

    return (
      <div className="main-content">
        <Paper className="management-vehicles">
          <div>
            <MenuList>
              {
                brands.map(item => (
                  <MenuItem
                    key={item.code}
                    selected={item.code === brandSelected}
                    onClick={() => {
                      this.fetchGroups(item.code);
                    }}
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} inset />
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                loadingCol2 ? <LoadingComponent classProgress="circle-progress" message="Cargando Grupos..." /> : groups.map(item => (
                  <MenuItem
                    key={item.code}
                    selected={item.code === groupSelected}
                    onClick={() => {
                      this.fetchModels(item.brand, item.code);
                    }}
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} inset />
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                loadingCol3 ? <LoadingComponent classProgress="circle-progress" message="Cargando Modelos..." /> : models.map(item => (
                  <MenuItem
                    key={item.code}
                    selected={item.code === modelSelected.code}
                    onClick={this.selectModel(item)}
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} secondary={item.code.toUpperCase()} />
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                loadingCol4 ? <LoadingComponent classProgress="circle-progress" message="Cargando Años..." /> : modelSelected.idYears.map(item => (
                  <MenuItem
                    key={item.id}
                    onClick={this.selectYear(item.id)}
                    className="menu-item"
                  >
                    <ListItemText className="padding-left-0 text-align-center" primary={item.year} secondary={`id: ${item.id}`} />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.handleSwitchModelYear(
                            item.id,
                            modelsMaintenance[item.id] ? !modelsMaintenance[item.id].show : true,
                          );
                        }}
                        checked={
                          modelsMaintenance[item.id] ? modelsMaintenance[item.id].show : false
                        }
                      />
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
        </Paper>
      </div>
    );
  }
}
