import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import NumberFormat from 'react-number-format';
import { Image } from 'cloudinary-react';

import LoadingComponent from './components/loading';
import EneFirestore from './../libs/ene/firestore';

class GeneralListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      messageLoading: 'Cargando Kilometrajes...',
      generals: [],
      milage: [],
      models: [],
      workshop: [],
      fetchingParts: false,
      fetchingWorks: false,
    };
    this.eneFirestore = new EneFirestore();
  }

  componentWillMount() {
    this.fetchWorkshop();
    this.fetchGroups();
  }

  fetchWorkshop = () => {
    this.eneFirestore.get(['workshop']).then((res) => {
      const workshop = [];
      if (!res.empty) {
        res.forEach(doc => workshop.push(doc.data()));
      }
      this.setState({
        workshop,
      });
    });
  }

  fetchMilage = (codeGroup, idModel, idYearModel) => {
    this.eneFirestore.getDocumentWhere(['maintenances'], [['show', '==', true]]).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          items.push(it);

          this.eneFirestore.get(['maintenances', it.code, 'models', idYearModel]).then((doc) => {
            if (doc.exists){
              const item = doc.data();

              const { models } = this.state;
              if (!models[it.code]){
                models[it.code] = [];
                models[it.code].push({group: codeGroup, model: idModel, review: item.review, parts: 0, works: 0, tpm: item.tpm});
              }else{
                models[it.code].push({group: codeGroup, model: idModel, review: item.review, parts: 0, works: 0, tpm: item.tpm});
              }

              this.fetchParts(it.code, idModel, item.parts);
              this.fetchWorks(it.code, idModel, item.works);
              const fetchingParts = item.parts ? item.parts.length > 0 : false;
              const fetchingWorks = item.works ? item.works.length > 0 : false;
              this.setState({ models, fetchingParts, fetchingWorks});
            }
          });
        });
        const milage = items.sort((a, b) => a.order - b.order);
        this.setState({ milage });
      }
    });
  }

  addModelState = () =>{
    const { models } = this.state;
    const _models = [];
    Object.keys(models).forEach((key) => {
      _models[key] = [];
      models[key].forEach((item) => {
        if (!_models[key][item.group]){
          _models[key][item.group] = {'total': item.review + item.parts + item.works, 'tpm': item.tpm || 0};
        }else{
          const oTotal = _models[key][item.group].total;
          const nTotal = item.review + item.parts + item.works;
          if (nTotal > oTotal){
            _models[key][item.group].total = nTotal;
          }

          if (item.tpm > _models[key][item.group].tpm){
            _models[key][item.group].tpm = item.tpm
          }
        }
      })      
    });
    return _models;
  }

  fetchParts = (codeKm, idModel, idParts) => {
    if (typeof idParts === 'undefined') return;
    const parts = [];
    idParts.forEach((it) => {
      this.eneFirestore.get(['parts', it.code]).then((doc) => {
        if (doc.exists) {
          const part = doc.data();
          part.quantity = it.quantity;

          part.amount = (part.amount * (1 + (part.percent / 100))).toFixed(2);

          parts.push(part);

          if (idParts.length === parts.length) {
            const { models } = this.state;
            const indexModel = models[codeKm].findIndex(item => item.model === idModel);
            const model = models[codeKm][indexModel];
            let totalParts = 0;
            parts.forEach(it => totalParts += it.quantity * it.amount);
            model.parts = totalParts;
            models[codeKm][indexModel] = model;

            this.setState({ models, loading: false });
          }
        }
      });
    });
  }

  fetchWorks = (codeKm, idModel, idWorks) => {
    if (idWorks === undefined) return

    const { workshop } = this.state;
    const works = [];
    let current = 0;
    idWorks.forEach((it) => {
      this.eneFirestore.get(['works', it]).then((res) => {
        if (res.exists) {
          const work = res.data();
          work.code = res.id;
          work.label = work.name;
          work.details = [];
          work.valueUnit = 1;
          if (workshop.length > 0){
            const warehouse = workshop.find(item => item.warehouse === work.workforce.warehouse);
            if (warehouse){
              work.valueUnit = warehouse.amount_hour;
            } 
          }
          this.eneFirestore.get(['works', it, 'details']).then((items) => {
            if (!items.empty) {
              items.forEach(item => (work.details.push(item.data())));
              works.push(work);
              current += 1;
              if (idWorks.length === current) {
                const { models } = this.state;
                const indexModel = models[codeKm].findIndex(item => item.model === idModel);
                const model = models[codeKm][indexModel];
                let totalWorks = 0;
                works.forEach((it) => {
                  totalWorks += it.workforce.quantity * it.valueUnit;
                  it.details.forEach((detail) => {
                    totalWorks += parseFloat(detail.amount);
                  });
                });
                model.works = totalWorks;
                models[codeKm][indexModel] = model;
                this.setState({ models,  loading: false });
              }
            }
          });
        }
      });
    });
  }


  fetchGroups = () => {
    this.eneFirestore.getDocumentWhere(['groups'], [['showList', '==', true]]).then((res) => {
      const generals = [];
      if (!res.empty) {
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          this.fetchModels(it);
          generals.push(it);
        });
      }
      this.setState({ generals });
    });
  }

  fetchModels = (group) => {
    this.eneFirestore.getDocumentWhere(
      ['models'],
      [
        ['group', '==', group.code],
        ['brand', '==', group.brand],
        ['show', '==', true],
      ],
    ).then((res) => {
      if (!res.empty) {
        res.forEach((doc) => {
          const model = doc.data();
          const years = model.year ? model.year.replace(/\s/g, '').split(',') : [];
          const idYears = [];
          years.forEach(item => idYears.push({ id: item.split(':')[0], year: item.split(':')[1] }));

          idYears.forEach((it) => {
            this.fetchMilage(group.code, model.code, it.id);
          })
        });
      }
    });
  }

  render() {
    const {
      loading, messageLoading,
      milage, generals
    } = this.state;

    if (loading) {
      return <LoadingComponent message={messageLoading} />;
    }
    
    const models = this.addModelState();

    return (
      <div className="main-content">
        <div className="general-content">
          <Table>
            <TableHead>
              <TableRow className="column-header">
                <TableCell>Marca</TableCell>
                {
                  milage.map(it => (
                    <TableCell key={it.code} align="right">
                      {it.name} <br /><span>(HASTA)</span>
                    </TableCell>))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                generals.map(item => (
                  <TableRow key={item.code} className="row-item">
                    <TableCell>
                      <span>{item.name}</span>
                      <Image
                        cloudName="enesoftec"
                        publicId={`autoroble/${item.brand}_${item.code}.jpg`}
                        className="img-grid-list"
                        width="100"
                        height="50"
                        crop="fill"
                        alt={item.name}
                        title={item.name}
                      />
                    </TableCell>
                    {
                      Object.keys(models).map((key) => {
                        const _item = models[key][item.code];

                        if (_item === undefined) return  <TableCell key={key} align="right" style={{ textAlign: 'center' }} />
                        
                        return (
                          <TableCell key={key} align="right" style={{ textAlign: 'center' }}>
                            <NumberFormat decimalScale={2} value={_item.total} displayType="text" thousandSeparator />

                            {_item.tpm > 0 && (<div className="text-sm color-red">
                              <NumberFormat decimalScale={0} value={_item.tpm} displayType="text" thousandSeparator prefix="MPT $ " />
                            </div>)}
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <div className="btn-actions-general">
            <Button variant="contained" color="secondary" href="/">Atrás</Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(GeneralListView);
