import React from 'react';

import { withRouter } from 'react-router-dom';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Delete from '@material-ui/icons/Delete';

import LoadingComponent from './../components/loading';

import EneFirestore from './../../libs/ene/firestore';

class MaintenanceManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenance: [],
      currentOrder: 1,
      name: '',
      loading: true,
    };
    this.eneFirestore = new EneFirestore();
  }

  componentDidMount() {
    this.fetchMaintenance();
  }

  onChangeName = (evt) => {
    this.setState({ name: evt.target.value });
  }

  fetchMaintenance = () => {
    this.eneFirestore.get(['maintenances']).then((res) => {
      let currentOrder = 1;
      let maintenance;
      if (!res.empty) {
        const docs = [];
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          docs.push(it);
          currentOrder = currentOrder < it.order ? it.order : currentOrder;
        });
        maintenance = docs.sort((a, b) => a.order - b.order);
      }
      this.setState({ maintenance, currentOrder, loading: false });
    });
  }

  handleSwitchItem = (key, value) => {
    this.eneFirestore.updateDocument(['maintenance', key], { show: value })
      .then(() => {
        this.fetchMaintenance();
      });
  }

  handleChangeOrder = (key, value) => (evt) => {
    const { maintenance } = this.state;
    const item = maintenance.filter(it => it.code === key)[0];
    const itemPrev = maintenance.filter(it => it.order === item.order - 1)[0];
    const itemNext = maintenance.filter(it => it.order === item.order + 1)[0];

    const up = value - item.order;
    if (up < 0) {
      if (itemPrev) {
        this.eneFirestore.updateDocument(['maintenance', itemPrev.code], { order: itemPrev.order + 1 });
      }
    } else if (up > 0) {
      if (itemNext) {
        this.eneFirestore.updateDocument(['maintenance', itemNext.code], { order: itemNext.order - 1 });
      }
    }
    this.eneFirestore.updateDocument(['maintenance', key], { order: value })
      .then(() => {
        this.fetchMaintenance();
      });
  }

  addMaintenance = () => {
    const newItem = {
      name: this.state.name,
      show: true,
      order: this.state.currentOrder + 1,
      price: 0,
      operations: [],
      parts: [],
    };
    this.eneFirestore.addDocument(['maintenance'], newItem)
      .then((res) => {
        if (res.id) {
          this.props.history.push(`maintenance/${res.id}`);
        }
      });
  }

  deleteItem = (key) => {
    this.eneFirestore.del(['maintenance', key])
      .then(() => {
        this.fetchMaintenance();
      });
    this.setState({
      loading: true,
    });
  }

  nextMaintenance = (key) => {
    this.props.history.push(`maintenance/${key}`);
  }

  render() {
    const {
      loading, currentOrder, maintenance,
    } = this.state;
    return (
      <div className="management-vehicles">
        <Paper className="paper-content">
          {
            loading ? <LoadingComponent classProgress="circle-progress" message="Cargando..." /> :
            <MenuList>
              {
                maintenance.map(item => (
                  <MenuItem
                    key={item.code}
                    onClick={() => {
                      this.nextMaintenance(item.code);
                    }}
                  >
                    <ListItemText className="padding-left-0 list-item-text" primary={item.name} inset />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.handleSwitchItem(item.code, !item.show);
                        }}
                        checked={item.show}
                      />
                      <IconButton className="full-height-width" disabled={item.order === 1} onClick={this.handleChangeOrder(item.code, item.order - 1)}>
                        <ExpandLess />
                      </IconButton>
                      <IconButton className="full-height-width" disabled={item.order === currentOrder} onClick={this.handleChangeOrder(item.code, item.order + 1)}>
                        <ExpandMore />
                      </IconButton>
                      <IconButton
                        className="full-height-width"
                        onClick={() => {
                          this.deleteItem(item.code);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))
              }
            </MenuList>
          }
        </Paper>
      </div>
    );
  }
}
export default withRouter(MaintenanceManagement);
