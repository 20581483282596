import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import reducers from './app/reducers';
import routes from './routes';

import './views/styles/main.css';

const store = createStore(reducers, applyMiddleware(thunk, ReduxPromise));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      { routes }
    </BrowserRouter>
  </Provider>,
  window.app,
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
