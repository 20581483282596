import React from 'react';

import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import EneApiClient from './../../libs/ene/api-client';
import EneFirestore from './../../libs/ene/firestore';


export default class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          id: 0, name: 'Marcas', update: false, loading: false, percent: 0,
        },
        {
          id: 1, name: 'Grupos', update: false, loading: false, percent: 0,
        },
        {
          id: 2, name: 'Modelos', update: false, loading: false, percent: 0,
        },
        {
          id: 3, name: 'Repuestos', update: false, loading: false, percent: 0,
        },
        {
          id: 4, name: 'Mantenimientos', update: false, loading: false, percent: 0,
        },
        {
          id: 5, name: 'Tabla General', update: false, loading: false, percent: 0,
        },
        {
          id: 6, name: 'Tarifas de Taller', update: false, loading: false, percent: 0,
        },
      ],
    };

    this.eneClient = new EneApiClient();
    this.eneFirestore = new EneFirestore();
  }

  switchUpdate = (id, value) => (evt) => {
    const { options } = this.state;
    const item = options[id];
    if (typeof item !== 'undefined') {
      item.update = value;
      options[id] = item;
      this.setState({ options });
    }
  }

  updateTest = () => {
    this.eneClient.GET().then((response) => {
      console.log(response);
      console.log(response.error, typeof response.error === 'undefined');
    });
  }

  updateBrands = () => {
    const { options } = this.state;
    const optionSelect = 0;
    const option = options[optionSelect];
    this.eneClient.GET('brands').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            item.order = key;
            this.eneFirestore.addDocument(['brands'], item, item.code).finally((value) => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('brands...');
  }

  updateGroups = () => {
    const { options } = this.state;
    const optionSelect = 1;
    const option = options[optionSelect];
    this.eneClient.GET('groups').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            this.eneFirestore.addDocument(['groups'], item, item.code).finally(() => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('groups...');
  }

  updateModels = () => {
    const { options } = this.state;
    const optionSelect = 2;
    const option = options[optionSelect];
    this.eneClient.GET('models').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            item.code = item.code.toString().trim().replace('/', '').replace(/ /g, '-');
            this.eneFirestore.addDocument(['models'], item, `${item.brand}-${item.code}`).finally(() => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('models...');
  }

  updateParts = (start = null) => {
    const { options } = this.state;
    const optionSelect = 3;
    const option = options[optionSelect];
    this.eneClient.GET('parts', start !== null ? { start } : null).then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            this.eneFirestore.addDocument(['parts'], item, item.code).finally(() => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              if (!option.loading) {
                this.updateParts(item.code);
              }
              this.setState({ options });
            });
          });
        } else {
          option.loading = false;
          options[optionSelect] = option;
          this.setState({ options });
        }
      }
    });
    console.log('parts...');
  }

  updateMaintenances = () => {
    const { options } = this.state;
    const optionSelect = 4;
    const option = options[optionSelect];
    this.eneClient.GET('maintenances').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            item.order = parseInt(key, 10) + 1;
            this.eneFirestore.addDocument(['maintenances'], item, item.km.toString()).finally(() => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('maintenances...');
  }

  updateGeneralTable = () => {
    const { options } = this.state;
    const optionSelect = 5;
    const option = options[optionSelect];
    this.eneClient.GET('general').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          const items = [];
          Object.keys(response).forEach((key) => {
            const item = response[key];            
            let index = items.findIndex((it) => it.group === item.group)
            if (index === -1) {
              items.push({
                name: item.name.trim(),
                group: item.group,
                kms: [{ km: item.km, amount: item.amount }],
              })
            } else {
              items[index].kms.push({ km: item.km, amount: item.amount });
            }
          });
          let count = 0;
          items.forEach((it) => {
            this.eneFirestore.addDocument(['generals'], it, it.group).finally(() => {
              count += 1;
              option.percent = (count / items.length) * 100;
              option.loading = count < items.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('general...');
  }

  updateWorkshop = () => {
    const { options } = this.state;
    const optionSelect = 6;
    const option = options[optionSelect];
    this.eneClient.GET('workshop').then((response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          let count = 0;
          Object.keys(response).forEach((key) => {
            const item = response[key];
            item.show = true;
            this.eneFirestore.addDocument(['workshop'], item, key).finally(() => {
              count += 1;
              option.percent = (count / response.length) * 100;
              option.loading = count < response.length;
              options[optionSelect] = option;
              this.setState({ options });
            });
          });
        }
      }
    });
    console.log('workshop...');
  }

  updateSelected = () => {
    console.log('updating...');
    const { options } = this.state;
    options.forEach((it) => {
      switch (it.id) {
        case 0: if (it.update) this.updateBrands(); break;
        case 1: if (it.update) this.updateGroups(); break;
        case 2: if (it.update) this.updateModels(); break;
        // case 3: if (it.update) this.updateParts(); break;
        case 4: if (it.update) this.updateMaintenances(); break;
        case 5: if (it.update) this.updateGeneralTable(); break;
        case 6: if (it.update) this.updateWorkshop(); break;
        default:
          console.log('no apply');
      }
      if (it.update && it.id !== 3) {
        options[it.id].loading = true;
        options[it.id].percent = 0;
      }
    });
    this.setState({ options });
  }

  render() {
    const { options } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <div className="container clearfix">
          <div className="tab-container">
            <Paper elevation={4} className="paper-container">
              <div className="item-tab-container">
                <List>
                  {
                    options.map((item) => {
                      let textPercent = '';
                      if (item.loading) {
                        textPercent = `Actualizando ${item.percent.toFixed(2)}% ...`;
                      }
                      return (
                        <ListItem key={item.id}>
                          <ListItemText className="padding-left-0" primary={item.name} secondary={textPercent} />
                          <ListItemSecondaryAction>
                            <Switch
                              onChange={this.switchUpdate(item.id, !item.update)}
                              checked={item.update}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })
                  }
                </List>
              </div>
              <Button variant="contained" color="secondary" onClick={this.updateSelected}>Actualizar</Button>
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}
