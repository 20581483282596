
import eneConfigApp from './../../app/config';

const nameWeb = eneConfigApp.title;

const EneUtils = {

  resetTitle: () => {
    document.title = nameWeb;
  },

  setCurrentTitle: (page, only = true) => {
    document.title = only ? `${page} | ${nameWeb}` : page;
  },

  // Cookies
  getCookie: (name) => {
    // eslint-disable-next-line no-underscore-dangle
    const _name = `${name}=`;
    const cookie = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < cookie.length; index++) {
      if (cookie[index].trim().indexOf(_name) === 0) {
        return cookie[index].trim().substring(_name.length, cookie[index].length);
      }
    }
    return null;
  },

  setCookie: (name, value) => {
    const d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  },

  removeCookie: (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  },

  getParamsGET(urlWithParams) {
    const queryArray = urlWithParams.split('?')[1].split('&');
    const queryParams = {};
    for (let i = 0; i < queryArray.length; i += 1) {
      const [key, val] = queryArray[i].split('=');
      queryParams[key] = val || true;
    }
    return queryParams;
  },

  formToJSON: elements => [].reduce.call(elements, (data, element) => {
    const dt = Object.assign({}, data);
    if (element.value && element.value !== '') {
      dt[element.name] = element.value;
    }
    return dt;
  }, {}),

  formatNumber: (value) => {
    const str = `${value} `;
    const x = str.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? `,${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1.$2');
    }
    return (x1 + x2);
  },

  removeFormatNumber: value => value.replace('.', ''),

};

export default EneUtils;
