import React from 'react';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import Loading from './../components/loading';
import AutoComplete from './../components/autocomplete';

import EneFirestore from './../../libs/ene/firestore';
import EneClient from './../../libs/ene/api-client';
import EneUtils from './../../libs/ene/utils';

export default class ItemMaintenanceManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      messageLoading: 'Cargando...',
      loadingAddNew: false,
      maintenance: null,
      mParts: [],
      mWorks: [],
      mOperations: [],
      mReview: 0,
      mTPM: 0,
      show: false,
      packagesOperations: [],
      works: [],
      quantity: 1,
    };
    this.eneFirestore = new EneFirestore();
    this.eneClient = new EneClient();
  }

  componentDidMount() {
    const { key, year } = this.props.match.params;
    this.fetchMaintenance(key, year);
    this.fetchOperations();
    this.fetchWorks();
  }

  onChange = name => (evt) => {
    this.setState({ [name]: evt.target.value });
  }

  fetchMaintenance = (key, year) => {
    this.eneFirestore.get(['maintenances', key]).then((res) => {
      if (res.exists) {
        const maintenance = res.data();
        this.eneFirestore.get(['maintenances', key, 'models', year]).then((res2) => {
          if (res2.exists) {
            const model = res2.data();
            this.setState({
              maintenance,
              mParts: [],
              mOperations: model.operations ? model.operations : [],
              mWorks: [],
              mReview: typeof model.review === 'object' || typeof model.review === 'undefined' ? 0 : model.review,
              mTPM: typeof model.tpm === 'object' || typeof model.tpm === 'undefined' ? 0 : model.tpm,
              show: maintenance.show,
              loading: false,
              messageLoading: 'Cargando...',
            });
            this.fetchParts(model.parts);
            this.fetchWorks(model.works);
          } else {
            this.setState({
              maintenance,
              show: maintenance.show,
              loading: false,
              messageLoading: 'Cargando...',
            });
          }
        });
        EneUtils.setCurrentTitle(`Mantenimiento ${maintenance.name}`);
      }
    });
  }

  fetchParts = (idParts) => {
    if (typeof idParts === 'undefined') return;
    const parts = [];
    idParts.forEach((it) => {
      this.eneFirestore.get(['parts', it.code]).then((doc) => {
        if (doc.exists) {
          const part = doc.data();
          part.quantity = it.quantity;

          part.amount = (part.amount * (1 + (part.percent / 100))).toFixed(2);

          parts.push(part);

          if (idParts.length === parts.length) {
            this.setState({ mParts: parts });
          }
        } else {
          this.eneClient.GET('parts', { code: it.code }).then((response) => {
            if (typeof response.error === 'undefined') {
              if (response.length > 0) {
                response.forEach((item) => {
                  const part = item;
                  this.eneFirestore.addDocument(['parts'], part, part.code);

                  part.quantity = it.quantity;
                  part.amount = (part.amount * (1 + (part.percent / 100))).toFixed(2);

                  parts.push(part);

                  if (idParts.length === parts.length) {
                    this.setState({ mParts: parts });
                  }
                });
              }
            }
          });
        }
      });
    });
  }

  fetchOperations = () => {
    this.eneFirestore.getDocumentWhere(['packages-operations'], [
      ['show', '==', true],
    ]).then((res) => {
      if (!res.etpmy) {
        const packagesOperations = [];
        res.forEach((it) => {
          const operation = it.data();
          operation.code = it.id;
          packagesOperations.push(operation);
        });
        this.setState({ packagesOperations });
      }
    });
  }

  fetchWorks = (idWorks = undefined) => {
    if (typeof idWorks === 'undefined') {
      this.eneFirestore.get(['works'], [
        ['show', '==', true],
      ]).then((res) => {
        const works = [];
        if (!res.etpmy) {
          res.forEach((it) => {
            const work = it.data();
            work.code = it.id;
            work.label = work.name;
            work.details = [];
            works.push(work);
          });
        }
        this.setState({ works });
      });
    } else {
      const works = [];
      let current = 0;
      idWorks.forEach((it) => {
        this.eneFirestore.get(['works', it]).then((res) => {
          current += 1;
          if (res.exists) {
            const work = res.data();
            work.code = res.id;
            work.label = work.name;
            works.push(work);
          }
          if (idWorks.length === current) {
            this.setState({ mWorks: works });
          }
        });
      });
    }
  }

  handleSwitch = (show) => {
    this.setState({ show });
  }

  handleUpdate = () => {
    const { key, year } = this.props.match.params;
    this.eneClient.GET('maintenance', { model: year, km: key }).then(async (response) => {
      if (typeof response.error === 'undefined') {
        if (response.length > 0) {
          const idParts = response.filter(it => it.part)
            .map((it) => {
              return {
                code: it.part,
                quantity: Number(it.quantity).toFixed(2),
              };
            });
          
          for (let i=0; i<idParts.length-1; i++){
            let code = idParts[i].code
            await this.eneClient.GET('parts', { code }).then((response) => {
              if (response.length > 0) {
                response.forEach(part => {
                  this.eneFirestore.addDocument(['parts'], part, part.code);
                })
              }
            })
          }
          
          this.fetchParts(idParts);

          let mReview = 0;
          response.filter(it => it.operation)
            .forEach((it) => {
              mReview += it.time * it.value_review_unit;
            });
          this.setState({ mReview, mParts: [] });
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('no se encontraron repuestos');
      }
    });
  }

  saveMaintenance = () => {
    const { key, year } = this.props.match.params;
    const {
      show, mParts, mOperations, mWorks, mReview, mTPM
    } = this.state;
    this.eneFirestore.updateDocument(['maintenances', key], { show })
      .then((res) => {
        const model = {
          parts: mParts.map(it => ({ code: it.code, quantity: it.quantity, percent: it.percent })),
          operations: mOperations,
          works: mWorks.map(it => it.code),
          review: mReview,
          tpm: parseFloat(mTPM.toFixed(2))
        };

        this.eneFirestore.addDocument(['maintenances', key, 'models'], model, year);

        this.fetchMaintenance(key, year);
      });

    this.setState({
      loading: true,
      messageLoading: 'Guardando...',
    });
  }

  addOperation = (operation) => {
    const { mOperations } = this.state;
    mOperations.push({ code: operation.code, name: operation.name });
    this.setState({ mOperations });
  }

  delOperation = value => () => {
    const { mOperations } = this.state;
    const indexToDel = mOperations.findIndex((item) => item.code === value);
    console.log(indexToDel, value)
    if (indexToDel >= 0) {
      mOperations.splice(indexToDel, 1);
      this.setState({ mOperations });
    }
  }

  addWork = (value) => {
    const { mWorks } = this.state;
    const work = value;
    mWorks.push(work);
    this.setState({ mWorks });
  }


  delWork = value => () => {
    const { mWorks } = this.state;
    const indexToDel = mWorks.indexOf(value);
    if (indexToDel >= 0) {
      mWorks.splice(indexToDel, 1);
      this.setState({ mWorks });
    }
  }

  quantityOnChange = (evt) => {
    this.setState({
      quantity: evt.target.value,
    });
  }

  render() {
    const {
      loading, messageLoading,
      maintenance, show, mParts, mWorks, mOperations, mReview, mTPM,
      packagesOperations, works,
    } = this.state;

    if (loading) {
      return <Loading message={messageLoading} />;
    }

    let totalParts = 0;
    mParts.forEach((it) => {
      totalParts += it.amount * it.quantity;
    });

    return (
      <div className="management-vehicles">
        <Paper className="paper-content">
          <div className="form-add-maintenance">
            <TextField
              className="field"
              label="Nombre"
              value={maintenance.name}
              disabled
            />
            <TextField
              className="field"
              label="Valor Mano de Obra"
              value={EneUtils.formatNumber(mReview.toFixed(2))}
              disabled
            />
            <TextField
              className="field"
              label="MPT"
              defaultValue={EneUtils.formatNumber(mTPM.toFixed(2))}
              onChange={(evt) => this.setState({mTPM: parseFloat(evt.target.value) || 0})}
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    this.handleSwitch(!show);
                  }}
                  checked={show}
                />
              }
              label="Mostrar"
            />
            <Button className="btn-margin-sides btn-save-item-maintenance" variant="contained" onClick={this.handleUpdate}>
              Actualizar
            </Button>
          </div>
          <div className="list-items-maintenance">
            <div>
              <h3>Repuestos ({EneUtils.formatNumber(totalParts.toFixed(2))})</h3>
              <MenuList>
                {
                  Object.keys(mParts).map((index) => {
                    const item = mParts[index];
                    return (
                      <MenuItem
                        key={index}
                      >
                        <ListItemText
                          className="padding-left-0"
                          primary={item.name.toUpperCase()}
                          secondary={`${item.quantity} x ${EneUtils.formatNumber(item.amount)} = ${EneUtils.formatNumber((item.amount * item.quantity).toFixed(2))}`}
                          inset
                        />
                      </MenuItem>
                    );
                  })
                }
              </MenuList>
            </div>
            <div>
              <h3>Operaciones</h3>
              <MenuList>
                {
                  Object.keys(mOperations).map((index) => {
                    const item = mOperations[index];
                    return (
                      <MenuItem
                        key={index}
                      >
                        <ListItemText className="padding-left-0" primary={item.name} inset />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Delete" onClick={this.delOperation(item.code)}>
                            <CloseIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </MenuItem>
                    );
                  })
                }
              </MenuList>
              <div className="autocomplete-operations">
                <AutoComplete
                  options={packagesOperations}
                  placeholder="Agregar paquete de operaciones"
                  onClick={this.addOperation}
                  field="name"
                />
              </div>
            </div>
            <div>
              <h3>Trabajos Adicionales</h3>
              <MenuList>
                {
                  Object.keys(mWorks).map((index) => {
                    const item = mWorks[index];
                    return (
                      <MenuItem
                        key={index}
                      >
                        <ListItemText className="padding-left-0" primary={item.name.toUpperCase()} inset />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Delete" onClick={this.delWork(item)}>
                            <CloseIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </MenuItem>
                    );
                  })
                }
              </MenuList>
              <div className="autocomplete-operations">
                <AutoComplete
                  options={works}
                  placeholder="Agregar otro Trabajo Adicional"
                  onClick={this.addWork}
                />
              </div>
            </div>
          </div>
          <Button className="btn-margin-sides btn-save-item-maintenance" variant="contained" color="secondary" onClick={this.saveMaintenance}>
            Guardar
          </Button>
        </Paper>
      </div>
    );
  }
}
