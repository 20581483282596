
import eneConfigApp from './../../app/config';
import EneUtils from './utils';

export default class EneApiClient {
  constructor(attrs = null) {
    this.url = null;
    this.api_key = null;

    const attr = {
      url: attrs == null ? eneConfigApp.api_url : attrs.url,
      api_key: attrs == null ? eneConfigApp.api_key : attrs.api_key,
    };

    if (attr.url) {
      if (attr.url.lastIndexOf('/') < attr.url.length - 1) {
        this.url = attr.url;
      } else throw new Error('format url invalid, url is with / at end');
    } else throw new Error('attribute url is required');

    this.api_key = attr.api_key;
  }

  GET(endPoint = '', data = null) {
    let dataSend = '';
    if (data != null) {
      Object.keys(data).forEach((key) => {
        const item = data[key];
        dataSend += `&${key}=${item}`;
      });
    }
    let end = dataSend !== '' ? dataSend : '';
    if (eneConfigApp.session) {
      const nsession = EneUtils.getCookie(eneConfigApp.session);
      if (nsession) {
        end = `&token=${nsession}${end}`;
      }
    }
    if (this.api_key) {
      dataSend = `?apikey=${this.api_key}${end}`;
    } else {
      dataSend = '?';
    }
    const epoint = endPoint === '' ? '' : `${endPoint}`;
    return fetch(`${this.url}/${epoint}${dataSend}`, { method: 'GET' })
      .then(request => request.json())
      .catch(error => error);
  }

  POST(endPoint = '', data = null) {
    const dataSend = data;
    if (eneConfigApp.session) {
      const nsession = EneUtils.getCookie(eneConfigApp.session);
      if (nsession) {
        dataSend.token = nsession;
      }
    }
    if (this.api_key) {
      dataSend.apikey = this.api_key;
    }

    const epoint = endPoint === '' ? '' : `${endPoint}/`;

    return fetch(`${this.url}/${epoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSend),
    })
      .then(request => request.json())
      .catch(error => error);
  }
}
