import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppBar from '@material-ui/core/AppBar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Button from '@material-ui/core/Button';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import { Image } from 'cloudinary-react';

import NumberFormat from 'react-number-format';

import LoadingComponent from './components/loading';

import EneUtils from './../libs/ene/utils';
import EneFirestore from './../libs/ene/firestore';

const TabContainer = props => (
  <div className="tab-container">
    <Paper elevation={4} className="paper-container">{props.children}</Paper>
  </div>
);

class ItemImageListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalLoading: false,
      messageModal: '',
      title: '',
      value: 0,
      showButtonNext: false,
      showButtonBack: false,
      showButtonGoModel: false,
      loading: true,
      group: {},
      models: [],
      modelSelected: {},
      maintenance: [],
      maintenanceSelected: {},
      parts: [],
      operations: [],
      works: [],
      workshop: [],
      review: 0,
      tpm: 0,
    };
    this.eneFirestore = new EneFirestore();
  }

  componentWillMount() {
    const modelSelected = this.props.match.params;

    this.eneFirestore.get(['groups', modelSelected.group])
      .then((res) => {
        if (res.exists) {
          const group = res.data();
          this.eneFirestore.getDocumentWhere(
            ['models'],
            [
              ['group', '==', modelSelected.group],
              ['brand', '==', modelSelected.brand],
              ['show', '==', true],
            ],
          ).then((res2) => {
            if (!res2.empty) {
              const items = [];
              res2.forEach((doc) => {
                const item = doc.data();
                item.img = `autoroble/${item.brand}_${item.group}_${item.code}`;
                item.version_img = item.vi ? item.vi : 1
                items.push(item);
              });

              this.setState({
                title: group.name,
                group,
                models: items,
                loading: false,
              });
            } else {
              this.setState({
                title: group.name,
                group,
                loading: false,
              });
            }
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
  }

  fetchMaintenance = (maintenance, modelYear) => {
    this.eneFirestore.get(['maintenances', maintenance, 'models', modelYear]).then((response) => {
      if (response.exists) {
        const maintenances = response.data();
        const { parts, works, operations } = maintenances;
        delete maintenances.parts;
        delete maintenances.operations;
        delete maintenances.works;
        this.fetchParts(parts);
        this.fetchWorks(works);
        if (typeof operations !== 'undefined') {
          operations.forEach(it => this.fetchOperations(it.code));
        }
        this.fetchWorkshop();
        this.setState({ ...maintenances });
      } else {
        this.setState({ parts: [], operations: [], works: [] });
      }
    });
  }

  fetchMaintenanceModels = async (maintenance, idYearList) => {
    let bigger = 0;
    let modelId = null
    
    for (let i = 0; i < idYearList.length; i++){
      let [id, year] = idYearList[i].split(':')
      
      const doc = await this.eneFirestore.get(['maintenances', maintenance, 'models', id])
      if (doc.exists){
        const item = doc.data()
        if (item.show){
          if (year > bigger){
            bigger = year;
            modelId = id
          }
        }      
      }
    }

    if (modelId !== null){
      this.fetchMaintenance(maintenance, modelId);
    }
  }

  fetchOperations = (packageOperation) => {
    this.eneFirestore.get(['packages-operations', packageOperation, 'operations']).then((res) => {
      const operations = [];
      if (!res.empty) {
        res.forEach((doc) => {
          const operation = doc.data();
          operation.code = doc.id;
          operations.push(operation);
        });
      }
      this.setState({
        operations,
      });
    });
  }

  fetchParts = (idParts) => {
    if (typeof idParts === 'undefined') return;
    const parts = [];
    let current = 0;
    idParts.forEach((it) => {
      this.eneFirestore.get(['parts', it.code]).then((doc) => {
        if (doc.exists) {
          const part = doc.data();
          part.quantity = it.quantity;

          part.amount = (part.amount * (1 + (part.percent / 100))).toFixed(2);

          parts.push(part);
        }
        current += 1;
        if (idParts.length === current) {
          this.setState({ parts });
        }
      });
    });
  }

  fetchWorks = (idWorks) => {
    const works = [];
    let current = 0;
    idWorks.forEach((it) => {
      this.eneFirestore.get(['works', it]).then((res) => {
        if (res.exists) {
          const work = res.data();
          work.code = res.id;
          work.label = work.name;
          work.details = [];
          this.eneFirestore.get(['works', it, 'details']).then((items) => {
            if (!items.empty) {
              items.forEach(item => (work.details.push(item.data())));
              works.push(work);
              current += 1;
              if (idWorks.length === current) {
                this.setState({ works });
              }
            }
          });
        }
      });
    });
  }

  fetchWorkshop = () => {
    this.eneFirestore.get(['workshop']).then((res) => {
      const workshop = [];
      if (!res.empty) {
        res.forEach(doc => workshop.push(doc.data()));
      }
      this.setState({
        workshop,
      });
    });
  }

  handleChange = (event, value) => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, value });
    }, 100);
  }

  handleBackTab = (evt) => {
    const items = 2;
    const { value } = this.state;
    const tab = value > 0 ? value - 1 : value;

    this.setState({
      value: tab,
      showButtonBack: tab > 0 && tab === items,
      showButtonGoModel: tab > 0,
    });
  }

  handleNextTab = (evt) => {
    const items = 2;
    const { value } = this.state;
    const tab = value < items ? value + 1 : value;

    if (tab === 1) {
      this.eneFirestore.getDocumentWhere(['maintenances'], [
        ['show', '==', true],
      ]).then((res) => {
        if (!res.empty) {
          const its = [];
          res.forEach((it) => {
            const item = it.data();
            item.code = it.id;
            its.push(item);
          });
          const maintenance = its.sort((a, b) => a.order - b.order);
          this.setState({
            showModalLoading: false,
            maintenance,
            parts: [],
            operations: [],
            works: [],
            value: tab,
            showButtonBack: tab === items,
            showButtonGoModel: tab > 0,
          });
        }
      });
    } else {
      this.setState({
        value: tab,
        showButtonBack: tab === items,
        showButtonGoModel: tab > 0,
      });
    }
  }

  handleGoModel = () => {
    const { group } = this.state;
    this.setState({
      title: group.name,
      value: 0,
      showButtonNext: false,
      showButtonGoModel: false,
      modelSelected: {},
      maintenanceSelected: {},
    });
  }

  handleGoHome = () => {
    this.props.location.href = '/';
  }

  handleCloseModal = () => {
    this.handleModal(false);
  }

  handleModal = (showModal = false, messageModal = '') => {
    this.setState({
      showModal, messageModal,
    });
  }

  print = () => {
    const toPDF = document.querySelector('.html-pdf');
    const printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Mantenimiento</title>');
    printWindow.document.write(`<style>
      *{ list-style: none } .icon {display:none}
      ul li { display: flex; align-items: center; margin-bottom: 10px; }
      ul li > div { flex-grow: 1 }
      ul li > div h3 { text-transform: capitalize; margin: 0; font-size: 1.05em}
      .foot { display: flex; justify-content: flex-end; }
      .items-works-total { display: flex; justify-content: flex-end; }
      .logo-center{display: flex; justify-content: center;}
    </style></head><body>`);
    printWindow.document.write('<div class="logo-center"><img src="/images/logo-autoroble.png" alt="logo" class="img-logo"></div>');
    printWindow.document.write(toPDF.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  }

  render() {
    const {
      showModal, showModalLoading, messageModal,
      title, loading, value,
      showButtonNext, showButtonBack, showButtonGoModel,
      models, modelSelected,
      maintenance, maintenanceSelected,
      parts, operations, works, review, workshop, tpm
    } = this.state;
    EneUtils.setCurrentTitle(title);

    let totalParts = 0;
    if (parts) {
      parts.forEach((it) => {
        totalParts += it.amount * it.quantity;
      });
    }
    let totalWorks = 0;
    if (works) {
      works.forEach((it) => {
        const valueUnit = workshop.length > 0 ? workshop
          .find(item => item.warehouse === it.workforce.warehouse).amount_hour : 0;
        totalWorks += it.workforce.quantity * valueUnit;
        it.details.forEach((detail) => {
          totalWorks += parseFloat(detail.amount);
        });
      });
    }
    return (
      <div>
        { value >= 1 &&
          <div className="head-banner">
            <div>
              <div>
                <h2>{title}</h2>
                <h3>{maintenanceSelected.name}</h3>
              </div>
            </div>
            <Image
              cloudName="enesoftec"
              publicId={`autoroble/${modelSelected.brand}_${modelSelected.group}.jpg`}
              version={modelSelected.version_img}
              className="img-grid-list"
              width="200"
              height="200"
              crop="scale"
              alt={title}
              title={title}
            />
          </div>
        }
        <AppBar position="static" className="app-bar">
          <Tabs value={value} className="tabs" centered>
            <Tab label="Modelos" className="text-tab" />
            <Tab label="Plan de Mantenimiento" className="text-tab" />
            <Tab label="Detalles del Mantenimiento" className="text-tab" />
          </Tabs>
        </AppBar>
        { loading ? <LoadingComponent /> :
        <div>
          <div className="container clearfix">
            { value === 0 &&
              <TabContainer>
                <div className="item-tab-container">
                  <p>Selecciona un Modelo</p>
                  <ImageList rowHeight={200} cols={4} className="grid-list-models">
                    {models.map(item => (
                      <ImageListItem
                        key={`${item.code}${Math.floor((Math.random() * 1000) + 1)}`}
                        className={item.code === '' ? '' : 'item-grid-list'}
                        onClick={() => {
                          this.setState({
                            title: `${title}-${item.name}`,
                            modelSelected: item,
                          });
                          this.handleNextTab();
                        }}
                      >
                        <Image
                          cloudName="enesoftec"
                          publicId={`${item.img}.jpg`}
                          version={item.version_img}
                          className="img-grid-list"
                          width="200"
                          height="200"
                          crop="scale"
                          alt={item.name}
                          title={item.name}
                        />
                        <ImageListItemBar
                          title={item.name}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              </TabContainer>
            }
            {value === 1 &&
              <TabContainer>
                <div className="item-tab-container">
                  <p>Selecciona un Plan de Mantenimiento</p>
                  <MenuList>
                    {maintenance.map(item => (
                      <MenuItem
                        key={item.code}
                        selected={item.code === maintenanceSelected.code}
                        alignItems="center"
                        onClick={() => {
                          const arrIdYears = modelSelected.year ? modelSelected.year.replace(/\s/g, '').split(',') : [];
                          this.setState({ maintenanceSelected: item });
                          this.fetchMaintenanceModels(item.code, arrIdYears)
                          this.handleNextTab();
                        }}
                      >
                        <ListItemText className="list-item-text" primary={item.name} />
                      </MenuItem>
                      ))
                    }
                  </MenuList>
                </div>
              </TabContainer>
            }
            {value === 2 &&
              <TabContainer>
                <div className="item-tab-container html-pdf">
                  <Accordion className="expand-panel" expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className="icon" />}>
                      <h2>Mantenimiento</h2>
                    </AccordionSummary>
                    <AccordionDetails className="expand-panel-details">
                      <List className="list">
                        <ListItem>
                          <ListItemText style={{ textAlign: 'left' }} primary="Mano de Obra" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={review} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ textAlign: 'left' }} primary="Repuestos" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={totalParts} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ textAlign: 'left' }} primary="Total de Operación (Desde)" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={totalParts + review} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ textAlign: 'left' }} primary="Trabajos Adicionales" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={totalWorks} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText style={{ textAlign: 'left' }} primary="Total de Operación (Hasta)" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={totalParts + review + totalWorks} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                        <ListItem className="color-red">
                          <ListItemText style={{ textAlign: 'left' }} primary="Mantenimiento Planeado Toyota (MPT)" />
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={tpm} displayType="text" thousandSeparator prefix="$ " />
                            }
                          />
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="expand-panel" defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className="icon" />}>
                      <h2>Detalles del Mantenimiento</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {
                          operations.map(operation => (
                            <ListItem key={operation.code}>
                              <ListItemText style={{ textAlign: 'left' }} primary={operation.name} />
                            </ListItem>
                            ))
                        }
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="expand-panel" defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className="icon" />}>
                      <h2>Repuestos de la Operación</h2>
                    </AccordionSummary>
                    <AccordionDetails className="expand-panel-details">
                      <List className="list">
                        {parts.map(part => (
                          <ListItem key={part.code}>
                            <ListItemText style={{ textAlign: 'left' }} primary={part.name} />
                            <ListItemText
                              style={{ textAlign: 'right' }}
                              primary={
                                <NumberFormat decimalScale={2} value={(part.amount * part.quantity).toFixed(2)} displayType="text" thousandSeparator prefix="$ " />
                              }
                            />
                          </ListItem>
                        ))}
                        <ListItem>
                          <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={
                              <NumberFormat decimalScale={2} value={totalParts} displayType="text" thousandSeparator prefix="TOTAL REPUESTOS: $ " />
                            }
                          />
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="expand-panel" defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className="icon" />}>
                      <h2>Trabajos Adicionales</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="items-works">
                        {
                          works.map((work) => {
                            const valueUnit = workshop.length > 0 ? workshop
                              .find(item => item.warehouse === work.workforce.warehouse)
                              .amount_hour : 0;
                            let totalWork = work.workforce.quantity * valueUnit;
                            return (
                              <div key={work.code} className="item-works">
                                <div className="head">{work.name.toUpperCase()}</div>
                                <div className="body">
                                  {
                                    <List>
                                      {
                                        work.details.map((detail) => {
                                          totalWork += parseFloat(detail.amount);
                                          return (
                                            <ListItem key={detail.name}>
                                              <ListItemText style={{ textAlign: 'left', textTransform: 'capitalize' }} primary={detail.name} />
                                              <ListItemText
                                                style={{ textAlign: 'right' }}
                                                primary={
                                                  <NumberFormat decimalScale={2} value={detail.amount} displayType="text" thousandSeparator prefix="$ " />
                                                }
                                              />
                                            </ListItem>
                                          );
                                        })
                                      }
                                      <ListItem>
                                        <ListItemText style={{ textAlign: 'left', textTransform: 'capitalize' }} primary="MANO DE OBRA" />
                                        <ListItemText
                                          style={{ textAlign: 'right' }}
                                          primary={
                                            <NumberFormat decimalScale={2} value={work.workforce.quantity * valueUnit} displayType="text" thousandSeparator prefix="$ " />
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  }
                                </div>
                                <div className="foot">
                                  <NumberFormat decimalScale={2} value={totalWork} displayType="text" thousandSeparator prefix={`TOTAL ${work.name.toUpperCase()}: $ `} />
                                </div>
                              </div>
                            );
                          })
                        }
                        <ListItemText
                          className="items-works-total"
                          primary={
                            <NumberFormat decimalScale={2} value={totalWorks} displayType="text" thousandSeparator prefix="TOTAL TRABAJOS ADICIONALES: $ " />
                          }
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Button variant="contained" color="secondary" onClick={this.print}>Descargar</Button>
                </div>
              </TabContainer>
            }
          </div>
          <div className="btns-bottom">
            <div>
              {showButtonNext &&
                <div className="btn-next">
                  <Button variant="contained" color="secondary" onClick={this.handleNextTab} >Siguiente</Button>
                </div>
              }
              {showButtonBack &&
                <div className="btn-next">
                  <Button variant="contained" color="secondary" onClick={this.handleBackTab} >Atras</Button>
                </div>
              }
              { showButtonGoModel &&
                <Button className="btn-extra" variant="contained" color="secondary" onClick={this.handleGoModel} >Ir a Modelos</Button>
              }
              <Button className="btn-extra" variant="contained" color="secondary" onClick={this.handleGoHome} >Ir a Inicio</Button>
            </div>
          </div>
        </div>
        }
        <Dialog
          open={showModal}
          onClose={this.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Autoroble</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageModal}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showModalLoading}
        >
          <DialogContent>
            <LoadingComponent />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: window.location,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ItemImageListView);
