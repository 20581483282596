import React from 'react';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Delete from '@material-ui/icons/Delete';

import LoadingComponent from './../components/loading';

import EneFirestore from './../../libs/ene/firestore';

export default class VehicleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      groups: [],
      models: [],
      brandSelected: '',
      groupSelected: '',
      messageLoading: 'Cargando...',
      loading: true,
      loadingCol2: false,
      loadingCol3: false,
      brand: '',
      group: '',
      codeModel: '',
      nameModel: '',
    };
    this.eneFirestore = new EneFirestore();
  }

  componentDidMount() {
    this.fetchMarks();
  }

  onChange = name => (evt) => {
    this.setState({
      [name]: evt.target.value,
    });
  }

  fetchMarks = () => {
    this.eneFirestore.getReference(['brands']).orderBy('order').get().then((res) => {
      if (!res.empty) {
        const docs = [];
        res.forEach((doc) => {
          docs.push(doc.data());
        });
        const itemSelected = docs[0];
        this.setState({ brands: docs, brandSelected: itemSelected.code });
        this.fetchGroups(itemSelected.code);
      }
    });
  }

  fetchGroups = (brand) => {
    this.eneFirestore.getDocumentWhere(['groups'], [['brand', '==', brand]]).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          items.push({
            brand: it.brand,
            code: it.code,
            name: it.name,
            show: it.show !== undefined ? it.show : false,
          });
        });
        this.setState({
          groups: items,
          brandSelected: brand,
          groupSelected: items[0].code,
          loadingCol2: false,
        });
        this.fetchModels(brand, items[0].code);
      } else {
        this.setState({
          loading: false,
          groups: [],
          brandSelected: brand,
          loadingCol2: false,
        });
      }
    });
    this.setState({
      loadingCol2: true,
    });
  }

  fetchModels = (brand, group) => {
    this.eneFirestore.getDocumentWhere(['models'], [
      ['brand', '==', brand],
      ['group', '==', group],
    ]).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          items.push({
            brand: it.brand,
            group: it.group,
            code: it.code,
            name: it.name,
            show: it.show !== undefined ? it.show : false,
          });
        });
        this.setState({
          models: items,
          brandSelected: brand,
          groupSelected: group,
          loading: false,
          loadingCol3: false,
        });
      } else {
        this.setState({
          models: [],
          brandSelected: brand,
          groupSelected: group,
          loading: false,
          loadingCol3: false,
        });
      }
    });

    this.setState({
      loadingCol3: true,
    });
  }

  handleSwitchBrand = (brand, value) => {
    this.eneFirestore.updateDocument(['brands', brand], { show: value })
      .then(() => {
        this.fetchMarks();
      });
  }

  handleSwitchGroup = (group, value) => {
    const { brandSelected } = this.state;
    this.eneFirestore.updateDocument(['groups', group], { show: value })
      .then(() => {
        this.fetchGroups(brandSelected);
      });
  }

  handleSwitchModel = (model, value) => {
    const { brandSelected, groupSelected } = this.state;
    this.eneFirestore.updateDocument(['models', model], { show: value })
      .then(() => {
        this.fetchModels(brandSelected, groupSelected);
      });
  }

  addBrand = () => {
    const { brand } = this.state;
    if (brand === '') {
      return;
    }
    const item = {
      code: brand.trim().replace(/ /g, '-'),
      name: brand,
      show: true,
      type: 'V',
    };
    this.eneFirestore.addDocument(['brands'], item, item.code).then(() => {
      this.fetchMarks();
    });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Marca...',
      brand: '',
    });
  }

  delBrand = brand => () => {
    this.eneFirestore.del(['brands', brand]).then(() => {
      this.fetchMarks();
    });
  }

  addGroup = () => {
    const { brandSelected, group } = this.state;
    if (brandSelected === '' && group === '') {
      return;
    }
    const item = {
      code: group.trim().replace(/ /g, '-'),
      name: group,
      brand: brandSelected,
      show: true,
    };
    this.eneFirestore.addDocument(['groups'], item, item.code).then(() => {
      this.fetchMarks();
    });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Grupo...',
      group: '',
    });
  }

  delGroup = group => () => {
    this.eneFirestore.del(['groups', group]).then(() => {
      this.fetchMarks();
    });
  }

  addModel = () => {
    const {
      brandSelected, groupSelected, codeModel, nameModel,
    } = this.state;
    if (brandSelected === '' && groupSelected === '' && codeModel === '' && nameModel === '') {
      return;
    }
    const item = {
      code: codeModel.trim(),
      name: nameModel,
      brand: brandSelected,
      group: groupSelected,
      show: true,
    };
    this.eneFirestore.addDocument(['models'], item, `${item.brand}-${item.code}`).then(() => {
      this.fetchMarks();
    });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Modelo...',
      codeModel: '',
      nameModel: '',
    });
  }

  delModel = model => () => {
    this.eneFirestore.del(['models', model]).then(() => {
      this.fetchMarks();
    });
  }

  render() {
    const {
      loading, loadingCol2, loadingCol3, messageLoading,
      brands, brandSelected, groups, groupSelected, models,
    } = this.state;

    if (loading) {
      return <LoadingComponent message={messageLoading} />;
    }

    return (
      <div className="main-content">
        <Paper className="management-vehicles">
          <div>
            <MenuList>
              {
                brands.map(item => (
                  <MenuItem
                    key={item.code}
                    selected={item.code === brandSelected}
                    onClick={() => {
                      this.fetchGroups(item.code);
                    }}
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} inset />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.handleSwitchBrand(item.code, !item.show);
                        }}
                        checked={item.show}
                      />
                      <IconButton className="full-height-width" onClick={this.delBrand(item.code)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                loadingCol2 ? <LoadingComponent classProgress="circle-progress" message="Cargando Grupos..." /> : groups.map(item => (
                  <MenuItem
                    key={item.code}
                    selected={item.code === groupSelected}
                    onClick={() => {
                      this.fetchModels(item.brand, item.code);
                    }}
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} inset />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.handleSwitchGroup(item.code, !item.show);
                        }}
                        checked={item.show}
                      />
                      <Link to={`/management/vehicles/${item.brand}/${item.code}`} href>
                        <IconButton className="full-height-width">
                          <AddAPhoto />
                        </IconButton>
                      </Link>
                      <IconButton className="full-height-width" onClick={this.delGroup(item.code)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                loadingCol3 ? <LoadingComponent classProgress="circle-progress" message="Cargando Modelos..." /> : models.map(item => (
                  <MenuItem
                    key={item.code}
                    className="menu-item"
                  >
                    <ListItemText className="padding-left-0 text-align-left" primary={item.name.toUpperCase()} secondary={item.code.toUpperCase()} />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={() => {
                          this.handleSwitchModel(`${item.brand}-${item.code}`, !item.show);
                        }}
                        checked={item.show}
                      />
                      <Link to={`/management/vehicles/${item.brand}/${item.group}/${item.code}`} href>
                        <IconButton className="full-height-width">
                          <AddAPhoto />
                        </IconButton>
                      </Link>
                      <IconButton className="full-height-width" onClick={this.delModel(`${item.brand}-${item.code}`)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))
              }
            </MenuList>
          </div>
        </Paper>
      </div>
    );
  }
}
