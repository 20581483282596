import React from 'react';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Loading from '../components/loading';
import AutoComplete from '../components/autocomplete';

import EneFirestore from '../../libs/ene/firestore';

export default class View extends React.Component {
  constructor(props) {
    super(props);
    this.eneFirestore = new EneFirestore();

    this.state = {
      loading: true,
      messageLoading: 'Cargando...',
      name: '',
      packages: [],
      operations: [],
      details: [],
      packageSelected: '',
      addingOperation: false,
    };
  }
  componentWillMount() {
    this.fetchPackages();
    this.fetchOperations();
  }

  onChangeField = name => (evt) => {
    this.setState({ [name]: evt.target.value });
  }

  fetchPackages = () => {
    this.eneFirestore.get(['packages-operations']).then((res) => {
      const packages = [];
      if (!res.empty) {
        res.forEach((it) => {
          const packageOperation = it.data();
          packageOperation.code = it.id;
          packages.push(packageOperation);
        });
        this.setState({
          packages,
          packageSelected: packages[0].code,
          loading: false,
          messageLoading: 'Cargando...',
        });
        this.fetchDetails(packages[0].code);
      } else {
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      }
    });
  }

  fetchOperations = () => {
    this.eneFirestore.get(['operations']).then((res) => {
      const operations = [];
      if (!res.empty) {
        res.forEach((it) => {
          const operation = it.data();
          operation.code = it.id;
          operations.push(operation);
        });
      }
      this.setState({
        operations,
        loading: false,
        messageLoading: 'Cargando...',
      });
    });
  }

  fetchDetails = (packageOperation) => {
    this.eneFirestore.get(['packages-operations', packageOperation, 'operations']).then((res) => {
      const operations = [];
      if (!res.empty) {
        res.forEach((doc) => {
          const operation = doc.data();
          operation.code = doc.id;
          operations.push(operation);
        });
      }
      this.setState({
        details: operations,
      });
    });
  }

  selectPackage = packageSelected => (evt) => {
    this.setState({
      packageSelected,
    });
    this.fetchDetails(packageSelected);
  }

  addPackageOperations = () => {
    const { name } = this.state;
    this.eneFirestore.addDocument(['packages-operations'], { name, show: true })
      .then((res) => {
        this.fetchPackages();
        this.setState({
          name: '',
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Paquete de Operaciones...',
    });
  }

  addOperation = packageSelected => (operation) => {
    const itemOperation = {
      name: operation.name, show: true,
    };

    this.eneFirestore.addDocument(['packages-operations', packageSelected, 'operations'], itemOperation, operation.code)
      .then((res) => {
        this.fetchDetails(packageSelected);
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Operación en el Paquete...',
    });
  }

  addNewOperation = value => () => {
    if (value !== '') {
      this.eneFirestore.addDocument(['operations'], { name: value, show: true })
        .then((res) => {
          this.fetchOperations();
          this.setState({
            addingOperation: false,
          });
        });
      this.setState({
        addingOperation: true,
      });
    }
  }

  handleSwitch = (collection, key, value) => (evt) => {
    this.eneFirestore.updateDocument([collection, key], { show: value })
      .then(() => {
        this.fetchPackages();
      });
  }

  deleteItem = key => (evt) => {
    this.eneFirestore.del(['packages-operations', key])
      .then(() => {
        this.fetchPackages();
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Eliminando Paquete de Operaciones...',
    });
  }

  deleteDetail = (packageOperation, key) => (evt) => {
    this.eneFirestore.del(['packages-operations', packageOperation, 'operations', key])
      .then(() => {
        this.fetchDetails(packageOperation);
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Eliminando Operación del paquete...',
    });
  }

  render() {
    const {
      loading, messageLoading,
      name, packages,
      operations, details, packageSelected,
      addingOperation,
    } = this.state;

    if (loading) {
      return <Loading message={messageLoading} />;
    }

    return (
      <div className="main-content">
        <Paper className="management-vehicles">
          <div>
            <div>
              <TextField
                label="Nombre del Paquete"
                value={name}
                onChange={this.onChangeField('name')}
              />
              <Button className="btn-margin-sides" variant="contained" color="secondary" onClick={this.addPackageOperations}>
                Agregar
              </Button>
            </div>
            <MenuList>
              {
                Object.keys(packages).map((key) => {
                  const item = packages[key];
                  return (
                    <MenuItem
                      key={key}
                      selected={item.code === packageSelected}
                      onClick={this.selectPackage(item.code)}
                    >
                      <ListItemText className="padding-left-0" primary={item.name} inset />
                      <ListItemSecondaryAction>
                        <Switch
                          onChange={this.handleSwitch('packages-operations', item.code, !item.show)}
                          checked={item.show}
                        />
                        <IconButton
                          className="full-height-width"
                          onClick={this.deleteItem(item.code)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  );
                })
              }
            </MenuList>
          </div>
          <div>
            <MenuList>
              {
                Object.keys(details).map((key) => {
                  const item = details[key];
                  return (
                    <MenuItem
                      key={key}
                    >
                      <ListItemText className="padding-left-0" primary={item.name} inset />
                      <ListItemSecondaryAction>
                        <Switch
                          onChange={this.handleSwitch(`packages-operations/${packageSelected}/operations`, item.code, !item.show)}
                          checked={item.show}
                        />
                        <IconButton
                          className="full-height-width"
                          onClick={this.deleteDetail(packageSelected, item.code)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  );
                })
              }
            </MenuList>
            <div className="form-add-part-to-maintenance" style={{ marginTop: 10, marginBottom: 10 }} >
              <div className="autocomplete">
                <AutoComplete
                  options={operations}
                  placeholder="Agregar Operación"
                  onClick={this.addOperation(packageSelected)}
                  onAddNew={this.addNewOperation}
                  loadingAddNew={addingOperation}
                  field="name"
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
