import { combineReducers } from 'redux';
import eneConfigApp from './config';

// Version Number
const versionNumber = eneConfigApp.version_number ? eneConfigApp.version_number : '1.0.0';

const reducers = combineReducers({
  version_app: () => versionNumber,
});

export default reducers;
