// Dependencies
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Views
import App from './app';
import IndexView from './views/index';
import ItemGridListView from './views/item-grid-list';

import ManagementView from './views/management';
import VehicleManagement from './views/management/vehicles';
import ImageVehicleManagement from './views/management/image-vehicles';
import MaintenanceManagement from './views/management/maintenance';
import ItemMaintenanceVehicle from './views/management/item-maintenance-vehicles';
import ItemMaintenanceManagement from './views/management/item-maintenance';
import OperationManagement from './views/management/operations';
import WorkManagement from './views/management/works';
import GeneralManagement from './views/management/general';
import UpdateManagement from './views/management/update';

import LoginView from './views/login';
import GeneralListView from './views/general';

export const MainRoutes = () => (
  <Switch>
    <Route path="/management" component={ManagementView} />

    <Route path="/general" component={GeneralListView} />

    <Route path="/:brand/:group" component={ItemGridListView} />

    <Route path="/login" component={LoginView} />
    <Route exact path="/" component={IndexView} />
    <Redirect from="*" to="/404" />
  </Switch>
);

export const ManagementRoutes = () => (
  <Switch>
    <Route path="/management/operations" component={OperationManagement} />
    <Route path="/management/additional-works" component={WorkManagement} />
    <Route path="/management/maintenance/:key/:year" component={ItemMaintenanceManagement} />
    <Route path="/management/maintenance/:key" component={ItemMaintenanceVehicle} />
    <Route path="/management/maintenance" component={MaintenanceManagement} />
    <Route path="/management/vehicles/:brand/:group/:model" component={ImageVehicleManagement} />
    <Route path="/management/vehicles/:brand/:group" component={ImageVehicleManagement} />
    <Route path="/management/vehicles" component={VehicleManagement} />
    <Route path="/management/general" component={GeneralManagement} />
    <Route path="/management/update-data" component={UpdateManagement} />
    <Redirect from="/management" to="/management/vehicles" />
  </Switch>
);

export default (
  <Switch>
    <Route exact path="/404" render={() => <h1>NOTFOUND</h1>} />
    <Route path="/" component={App} />
  </Switch>
);
