import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingComponent = props => (
  <div style={{ textAlign: 'center', padding: '10%' }}>
    <CircularProgress className={props.classProgress} color="primary" />
    <h3>{props.message}</h3>
  </div>
);

export default LoadingComponent;
