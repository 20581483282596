const eneConfigApp = {
  title: 'Autoroble',
  version_number: '1.0.0',
  api_url: 'https://www.autoroblepe.com:8050',
  api_key: '7EwX8M75zSpJ1MDzH2GhZgZnrznXD2GyrwMK148WjhmYPH60Ko9OIajgfdJ04N75',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBdZ3r5egb0qIykOp1vRZ2MFUxru73v8tc',
  authDomain: 'autoroble-bfc09.firebaseapp.com',
  projectId: 'autoroble-bfc09',
};

export default eneConfigApp;
