import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import EneFirestore from './../libs/ene/firestore';

class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showChip: false,
      messageChip: '',
    };
    this.eneFirestore = new EneFirestore();
  }

  componentWillMount() {
    this.eneFirestore.fetchUser().then((user) => {
      if (this.eneFirestore.isLogged()) {
        this.props.location.href = '/management';
      }
    });
  }

  handleChange = name => (evt) => {
    this.setState({
      [name]: evt.target.value,
    });
  }

  handleLogin = () => {
    const { email, password } = this.state;
    this.eneFirestore.login({ email, password })
      .then((res) => {
        this.props.location.href = '/management';
      }).catch((error) => {
        let { message } = error;
        switch (error.code) {
          case 'auth/user-not-found':
            message = 'Este usuario no existe, quizas fue eliminado';
            break;
          default:
            break;
        }
        this.setState({
          showChip: true,
          messageChip: message,
        });
      });
    this.setState({
      showChip: true,
      messageChip: 'Iniciando Sesión...',
    });
  }

  render() {
    const {
      email, password, showChip, messageChip,
    } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            elevation={4}
            style={{
              minWidth: '40%', marginTop: '5%', padding: 20, minHeight: 300,
            }}
          >
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  value={email}
                  required
                  type="email"
                  id="email"
                  label="Email"
                  margin="normal"
                  onChange={this.handleChange('email')}
                />
              </div>
              <div>
                <TextField
                  value={password}
                  required
                  type="password"
                  id="password"
                  label="Contraseña"
                  margin="normal"
                  onChange={this.handleChange('password')}
                />
              </div>
            </form>
            <Button variant="contained" color="secondary" onClick={this.handleLogin}>Iniciar Sesión</Button>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              { showChip && <Chip label={messageChip} /> }
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: window.location,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
