import React from 'react';

import { Link, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { ManagementRoutes } from './../routes';

import LoadingComponent from './components/loading';

import EneApiClient from './../libs/ene/api-client';
import EneFirestore from './../libs/ene/firestore';

class ManagementView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.eneClient = new EneApiClient();
    this.eneFirestore = new EneFirestore();
  }

  componentWillMount() {
    this.eneFirestore.fetchUser().then((user) => {
      if (!this.eneFirestore.isLogged()) {
        window.location.href = '/login';
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  fetchWhenIsActive = (path) => {
    const { pathname } = this.props.location;
    return pathname.indexOf(`/management/${path}`) === 0 ? 'secondary' : 'inherit';
  }

  render() {
    const {
      loading
    } = this.state;

    if (loading) {
      return <LoadingComponent message="Verificando Sesión..." />;
    }

    return (
      <div>
        <AppBar className="app-bar-management">
          <Toolbar>
            <div>
              <Button variant="contained" color={this.fetchWhenIsActive('vehicles')}>
                <Link to="/management/vehicles" href >Vehículos</Link>
              </Button>
              <Button variant="contained" color={this.fetchWhenIsActive('general')}>
                <Link to="/management/general" href>Tabla General</Link>
              </Button>
              <Button variant="contained" color={this.fetchWhenIsActive('maintenance')}>
                <Link to="/management/maintenance" href>Mantenimientos</Link>
              </Button>
              <Button variant="contained" color={this.fetchWhenIsActive('operations')}>
                <Link to="/management/operations" href>Operaciones de Mantenimiento</Link>
              </Button>
              <Button variant="contained" color={this.fetchWhenIsActive('additional-works')}>
                <Link to="/management/additional-works" href>Trabajos Adicionales</Link>
              </Button>
              <Button variant="contained" color={this.fetchWhenIsActive('update-data')}>
                <Link to="/management/update-data" href>Actualizar Datos</Link>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <div className="main-content margin-top">
          <ManagementRoutes />
        </div>
      </div>
    );
  }
}

export default withRouter(ManagementView);
