import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { firebaseConfig } from './../../app/config';

export default class EneFirestore {
  constructor() {
    const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.loaded = false;
  }
  // User Login
  provider = (_provider) => {
    switch (_provider) {
      case 'email':
        return new firebase.auth.EmailAuthProvider();
      case 'facebook':
        return new firebase.auth.FacebookAuthProvider();
      case 'google':
        return new firebase.auth.GoogleAuthProvider();
      default:
        throw new Error('Provider is not supported!!!');
    }
  }

  login = credentials => this.auth
    .signInWithEmailAndPassword(credentials.email, credentials.password)

  logout = () => this.auth.signOut()

  isLogged = () => this.auth.currentUser !== null;

  getIdToken = () => this.auth.currentUser.getIdToken()

  fetchUser = () => new Promise((res, rej) => {
    this.auth.onAuthStateChanged((user) => {
      res(user);
    });
  })

  getReference = (paths = []) => {
    let ref = this.firestore.collection(paths[0]);
    if (paths.length > 1) {
      ref = ref.doc(paths[1]);
      paths.forEach((path, index) => {
        if (index > 1) {
          if (index % 2 === 0) {
            ref = ref.collection(path);
          } else {
            ref = ref.doc(path);
          }
        }
      });
    }
    return ref;
  }

  addDocument = (paths, document, keyDocument = null) => {
    if (keyDocument !== null) {
      return this.getReference(paths).doc(keyDocument).set(document, { merge: true });
    }
    return this.getReference(paths).add(document);
  }

  updateDocument = (paths, fieldValue) => this.getReference(paths).set(fieldValue, { merge: true })

  get = paths => this.getReference(paths).get()

  getDocumentWhere = (paths, wheres = [], orderBy = null) => {
    let query = this.getReference(paths);
    wheres.forEach((where) => {
      query = query.where(where[0], where[1], where[2]);
    });
    if (orderBy) {
      query = query.orderBy(orderBy);
    }
    return query.get();
  }

  del = paths => this.getReference(paths).delete()
}
