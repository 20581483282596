import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import GridListComponent from './components/grid-list';

import EneFirestore from './../libs/ene/firestore';

class IndexView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      data: [],
      searchBrands: [],
      brandSelected: { code: '', name: '', type: '' },
    };
    this.eneFirestore = new EneFirestore();
  }

  componentWillMount() {
    this.eneFirestore.getDocumentWhere(['brands'], [['show', '==', true]], 'order').then((res) => {
      if (!res.empty) {
        const docs = [];
        res.forEach((doc) => {
          docs.push(doc.data());
        });
        docs.sort((b1, b2) => {
          if (b2.name > b1.name) return 1;
          if (b2.name < b1.name) return -1;
          return 0;
        });
        const itemSelected = docs[0];
        this.setState({ brands: docs, searchBrands: docs });
        this.handleGroups(itemSelected);
      }
    });
  }

  handleGroups = (item) => {
    const dataBrand = [];
    this.eneFirestore.getDocumentWhere(['groups'], [
      ['brand', '==', item.code],
      ['show', '==', true],
    ]).then((res) => {
      if (!res.empty) {
        const itemsBrand = [];
        res.forEach((doc) => {
          const it = doc.data();
          itemsBrand.push({
            code: it.code,
            title: it.name,
            img: `autoroble/${item.code}_${it.code}`,
            version_img: it.vi ? it.vi : 1,
            uri: `${item.code}/${it.code}`,
          });
        });
        dataBrand.push({ code: item.code, title: item.name, items: itemsBrand });
        this.setState({
          data: dataBrand,
          brandSelected: item,
        });
      }
    });
  }

  handleModels = (item) => {
    const dataBrand = [];
    this.eneFirestore.getDocumentWhere(['models'], [
      ['brand', '==', item.code],
      ['show', '==', true],
    ]).then((res) => {
      if (!res.empty) {
        const itemsBrand = [];
        res.forEach((doc) => {
          const it = doc.data();
          itemsBrand.push({
            code: it.code,
            title: it.name,
            year: it.year,
            img: `autoroble/${item.code}_${it.code}`,
            version_img: it.vi ? it.vi : 1,
            uri: `${item.code}/${it.code}`,
          });
        });
        dataBrand.push({ code: item.code, title: item.name, items: itemsBrand });
        this.setState({
          data: dataBrand,
          brandSelected: item,
        });
      }
    });
  }

  searchMark = (evt) => {
    const search = evt.target.value;
    setTimeout(() => {
      const { brands } = this.state;
      const filters = brands
        .filter(elem => elem.name.toLowerCase().startsWith(search.toLowerCase()));
      if (search && search !== '') {
        this.setState({
          searchBrands: filters,
        });
      } else {
        this.setState({
          searchBrands: brands,
        });
      }
    }, 10000);
  }

  render() {
    const { searchBrands, data, brandSelected } = this.state;
    return (
      <div className="main-content">
        <div className="list-brands">
          <TextField label="Buscar marca..." onKeyUp={this.searchMark} />
          <MenuList>
            {
              searchBrands.length > 0 ? searchBrands.map(item => (
                <MenuItem
                  key={item.code}
                  selected={item.code === brandSelected.code}
                  onClick={() => {
                    this.handleGroups(item);
                  }}
                >
                  <ListItemText className="padding-left-0" primary={item.name} inset />
                </MenuItem>
              )) : ''
            }
          </MenuList>
          <Button className="btn-update-data" variant="contained" color="secondary" href="/general">Tabla General</Button>
        </div>
        <div className="mark-content">
          {
            data.length > 0 && data.map(item => <GridListComponent key={item.code} data={item} />)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(IndexView);
