import React from 'react';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Loading from './../components/loading';
import AutoComplete from './../components/autocomplete';

import EneUtils from './../../libs/ene/utils';
import EneApiClient from './../../libs/ene/api-client';
import EneFirestore from './../../libs/ene/firestore';

export default class WorkManagement extends React.Component {
  constructor(props) {
    super(props);
    this.eneFirestore = new EneFirestore();
    this.eneClient = new EneApiClient();

    this.state = {
      loading: true,
      messageLoading: 'Cargando...',
      name: '',
      detail: '',
      amount: 0,
      works: [],
      details: [],
      quantityPart: 1,
      quantityWorkforce: 1,
      warehouseWorkforce: 0,
      percentWorkforce: 0,
      workforce: { quantity: 1, warehouse: 0, percent: 0 },
      valueWorkforce: 0,
      parts: [],
      workshop: [],
      workSelected: '',
      addWorkforce: false,
    };
  }
  componentWillMount() {
    // this.fetchParts();
    this.fetchWorks();
    this.fetchWorkshop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.addWorkforce) {
      if (prevState.quantityWorkforce !== this.state.quantityWorkforce ||
        prevState.warehouseWorkforce !== this.state.warehouseWorkforce ||
        prevState.percentWorkforce !== this.state.percentWorkforce) {
        this.addWorkforce();
      }
    }
  }

  onChangeField = name => (evt) => {
    this.setState({ [name]: evt.target.value });
  }

  onChangeWarehouse = (evt) => {
    const { workshop, workforce } = this.state;
    const value = workshop.find(it => it.warehouse === parseInt(evt.target.value, 10));
    if (value) {
      workforce.warehouse = value.warehouse;
      this.setState({ workforce, warehouseWorkforce: value.warehouse, addWorkforce: true });
    }
  }

  fetchParts = () => {
    this.eneFirestore.get(['parts'], [
      ['show', '==', true],
    ]).then((res) => {
      if (!res.empty) {
        const parts = [];
        res.forEach((it) => {
          const part = it.data();
          part.code = it.id;
          part.quantity = !part.quantity ? 1 : part.quantity;
          part.amount = (part.amount * (1 + (part.percent / 100))).toFixed(2);
          parts.push(part);
        });
        this.setState({ parts });
      }
    });
  }

  fetchWorks = () => {
    this.eneFirestore.get(['works']).then((res) => {
      const works = [];
      if (!res.empty) {
        let { workSelected } = this.state;
        res.forEach((it) => {
          const work = it.data();
          work.code = it.id;
          works.push(work);
        });
        if (works.length > 0) workSelected = workSelected === '' ? works[0].code : workSelected;
        this.setState({
          works,
          workSelected,
          loading: false,
          messageLoading: 'Cargando...',
        });
        this.fetchDetails(workSelected);
      } else {
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      }
    });
  }

  fetchDetails = (work) => {
    this.eneFirestore.get(['works', work, 'details']).then((res) => {
      const details = [];
      if (!res.empty) {
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          details.push(it);
        });
      }
      const { works, workshop } = this.state;
      const workSelected = works.find(it => it.code === work);
      const { workforce } = workSelected;
      let valueUnit = 0
      if (workforce){
        valueUnit = workshop.find(it => it.warehouse === workforce.warehouse).amount_hour;
      }
      this.setState({
        details,
        quantityWorkforce: workforce ? workforce.quantity : 0,
        warehouseWorkforce: workforce ? workforce.warehouse : 0,
        valueWorkforce: workforce ? workforce.quantity * valueUnit : 0,
        workforce: workforce || { quantity: 1, warehouse: 0 },
      });
    });
  }

  fetchWorkshop = () => {
    this.eneFirestore.get(['workshop']).then((res) => {
      const workshop = [];
      if (!res.empty) {
        res.forEach(doc => workshop.push(doc.data()));
      }
      this.setState({
        workshop,
      });
    });
  }

  selectWork = workSelected => (evt) => {
    this.setState({
      workSelected,
      quantityWorkforce: 1,
      warehouseWorkforce: 0,
      percentWorkforce: 0,
      valueWorkforce: 0,
      workforce: { quantity: 1, warehouse: 0, percent: 0 },
    });
    this.fetchDetails(workSelected);
  }

  addWork = () => {
    const { name } = this.state;
    this.eneFirestore.addDocument(['works'], { name, show: true })
      .then((res) => {
        this.fetchWorks();
        this.setState({
          name: '',
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Guardando Trabajo Adicional...',
    });
  }

  addDetail = workSelected => (part) => {
    if (workSelected) {
      const { quantityPart } = this.state;
      const itemDetail = {
        name: part.name,
        quantity: quantityPart,
        amount: (part.amount * quantityPart).toFixed(2),
        show: true,
      };

      // Copying Part
      this.eneFirestore.addDocument(['parts'], part, part.code);

      this.eneFirestore.addDocument(['works', workSelected, 'details'], itemDetail)
        .then((res) => {
          this.fetchDetails(workSelected);
          this.setState({
            detail: '',
            amount: 0,
            loading: false,
            messageLoading: 'Cargando...',
          });
        });
      this.setState({
        loading: true,
        messageLoading: 'Guardando Detalle de Trabajo Adicional...',
      });
    }
  }

  addWorkforce = () => {
    const { workSelected, workforce, workshop } = this.state;
    console.log(workforce)
    const valueUnit = workshop.find(it => it.warehouse === workforce.warehouse).amount_hour;
    workforce.quantity = Number.isNaN(parseFloat(workforce.quantity)) ? 1 : workforce.quantity;
    workforce.percent = Number.isNaN(parseFloat(workforce.percent)) ? 0 : workforce.percent;
    this.eneFirestore.updateDocument(['works', workSelected], { workforce })
      .then(() => this.setState({
        addWorkforce: false,
        valueWorkforce: (workforce.quantity * valueUnit) * (1 + (workforce.percent / 100)),
      }));
  }

  handleSwitch = (collection, key, value) => (evt) => {
    this.eneFirestore.updateDocument([collection, key], { show: value })
      .then(() => {
        this.fetchWorks();
      });
  }

  handleSwitchDetails = (work, key, value) => (evt) => {
    this.eneFirestore.updateDocument(['works', work, 'details', key], { show: value })
      .then(() => {
        this.fetchWorks();
      });
  }

  deleteItem = key => (evt) => {
    this.eneFirestore.get(['works', key, 'details']).then((res) => {
      if (!res.empty) {
        res.forEach(doc => this.eneFirestore.del(['works', key, 'details', doc.id]));
      }
      this.eneFirestore.del(['works', key])
        .then(() => {
          this.fetchWorks();
          this.setState({
            loading: false,
            workSelected: '',
            messageLoading: 'Cargando...',
            works: [],
            details: [],
            workforce: { quantity: 1, warehouse: 0, percent: 0 },
            valueWorkforce: 0,
          });
        });
    });
    this.setState({
      loading: true,
      messageLoading: 'Eliminando Trabajo Adicional...',
    });
  }

  deleteDetail = (work, key) => (evt) => {
    this.eneFirestore.del(['works', work, 'details', key])
      .then(() => {
        this.fetchWorks();
        this.setState({
          loading: false,
          messageLoading: 'Cargando...',
        });
      });
    this.setState({
      loading: true,
      messageLoading: 'Eliminando Detalle Trabajo Adicional...'
    });
  }

  addPart = (value) => {
    const { maintenance, quantity } = this.state;
    const part = value;
    part.quantity = quantity !== '' ? quantity : 1;
    maintenance.parts.push(part);
    this.setState({
      maintenance,
    });
  }

  delPart = value => () => {
    const { maintenance } = this.state;
    const indexToDel = maintenance.parts.indexOf(value);
    if (indexToDel >= 0) {
      maintenance.parts.splice(indexToDel, 1);
      this.setState({
        maintenance,
      });
    }
  }

  quantityPartOnChange = (evt) => {
    this.setState({
      quantityPart: evt.target.value,
    });
  }

  quantityWorkshopOnChange = (evt) => {
    const { workforce, warehouseWorkforce } = this.state;
    const quantity = parseFloat(evt.target.value);
    workforce.quantity = Number.isNaN(quantity) ? 0 : quantity
    this.setState({ workforce, quantityWorkforce: workforce.quantity, addWorkforce: warehouseWorkforce > 0 });
  }

  percentOnChange = (evt) => {
    const { workforce, warehouseWorkforce } = this.state;
    const percent = parseFloat(evt.target.value);
    workforce.percent = Number.isNaN(percent) ? 0 : percent
    this.setState({ workforce, percentWorkforce: workforce.percent, addWorkforce:  warehouseWorkforce > 0 });
  }

  render() {
    const {
      loading, messageLoading,
      name, works, details, workSelected,
      quantityPart, workshop,
      workforce, valueWorkforce,
    } = this.state;

    if (loading) {
      return <Loading message={messageLoading} />;
    }

    return (
      <div className="main-content">
        <Paper className="management-vehicles">
          <div>
            <div>
              <TextField
                label="Nombre del Trabajo"
                value={name}
                onChange={this.onChangeField('name')}
              />
              <Button className="btn-margin-sides" variant="contained" color="secondary" onClick={this.addWork}>
                Agregar
              </Button>
            </div>
            <MenuList>
              {
                Object.keys(works).map((key) => {
                  const item = works[key];
                  return (
                    <MenuItem
                      key={key}
                      selected={item.code === workSelected}
                      onClick={this.selectWork(item.code)}
                    >
                      <ListItemText className="padding-left-0" primary={item.name} inset />
                      <ListItemSecondaryAction>
                        <Switch
                          onChange={this.handleSwitch('works', item.code, !item.show)}
                          checked={item.show}
                        />
                        <IconButton
                          className="full-height-width"
                          onClick={this.deleteItem(item.code)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  );
                })
              }
            </MenuList>
          </div>
          <div>
            <p>Repuestos</p>
            <MenuList>
              {
                Object.keys(details).map((key) => {
                  const item = details[key];
                  return (
                    <MenuItem
                      key={key}
                    >
                      <ListItemText className="padding-left-0" primary={item.name} secondary={EneUtils.formatNumber(item.amount)} inset />
                      <ListItemSecondaryAction>
                        <Switch
                          onChange={this.handleSwitchDetails(workSelected, item.code, !item.show)}
                          checked={item.show}
                        />
                        <IconButton
                          className="full-height-width"
                          onClick={this.deleteDetail(workSelected, item.code)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  );
                })
              }
            </MenuList>
            <div className="form-add-part-to-maintenance" style={{ marginTop: 10, marginBottom: 10 }} >
              <TextField
                type="number"
                label="Cantidad"
                onChange={this.quantityPartOnChange}
                value={quantityPart}
                className="field-quantity"
              />
              <div className="autocomplete">
                <AutoComplete
                  remote
                  sourceOptions={filter => this.eneClient.GET('parts', { start: filter, limit: 100 })
                    .then((response) => {
                      if (typeof response.error === 'undefined') {
                        return response;
                      }
                      return [];
                    })
                  }
                  placeholder="Agregar repuesto"
                  onClick={this.addDetail(workSelected)}
                  field="name"
                />
              </div>
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <p>Mano de Obra</p>
            <div className="form-add-part-to-maintenance" style={{ marginTop: 10, marginBottom: 10 }} >
              <TextField
                type="number"
                label="Cantidad"
                onChange={this.quantityWorkshopOnChange}
                value={workforce.quantity}
                className="field-quantity"
              />
              <div className="autocomplete">
                <Select native onChange={this.onChangeWarehouse} value={workforce.warehouse}>
                  <option value="0">Seleccionar Bodega</option>
                  {workshop.map(it => (<option key={it.warehouse} value={it.warehouse}>{`Bodega ${it.warehouse}`}</option>))}
                </Select>
              </div>
              <TextField
                type="number"
                label="IVA (%)"
                onChange={this.percentOnChange}
                value={workforce.percent}
                className="field-quantity"
              />
            </div>
            <h2>$ {!Number.isNaN(workforce.quantity) || !Number.isNaN(workforce.percent) ?
              EneUtils.formatNumber(valueWorkforce) : 0}
            </h2>
          </div>
        </Paper>
      </div>
    );
  }
}
