import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import LoadingComponent from './../views/components/loading';

import { MainRoutes } from './../routes';

import EneFirestore from './../libs/ene/firestore';

const theme = createTheme({
  overrides: {
    MuiButton: {
      text: {
        color: '#000',
      },
    },
  },
  palette: {
    primary: {
      main: grey[800],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#FF5252',
    },
  },
  typography: {
    // Use the system font over Roboto.
    useNextVariants: true,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      loading: true,
    };
    this.eneFirestore = new EneFirestore();
  }
  componentWillMount() {
    this.eneFirestore.fetchUser().then((user) => {
      const logged = this.eneFirestore.isLogged();
      this.setState({ loading: false, logged });
    });
  }

  handleLogout = () => {
    this.eneFirestore.logout().then(() => {
      this.props.location.href = '/login';
    });
  };

  render() {
    const { loading, logged } = this.state;
    if (loading) {
      return <MuiThemeProvider theme={theme}><LoadingComponent /></MuiThemeProvider>;
    }
    return (
      <MuiThemeProvider theme={theme}>
        <AppBar className="app-bar">
          <Toolbar>
            <div style={{ flex: 1 }}>
              <a href="/" title="Pagina Inicio">
                <img src="/images/logo.png" alt="logo" className="img-logo" />
              </a>
            </div>
            <Button href="http://autoroble.com.co/" variant="contained" color="secondary">
              Regresar a Página Principal
            </Button>
            {logged &&
              <div>
                <Button href="/management" variant="contained">
                  Gestionar
                </Button>
                <Button variant="contained" onClick={this.handleLogout}>
                  Cerrar Sesión
                </Button>
              </div>
            }
          </Toolbar>
        </AppBar>
        <div className="app-container">
          <MainRoutes />
        </div>
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = state => ({
  location: window.location,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
