import React from 'react';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import CircularProgress from '@material-ui/core/CircularProgress';

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.remote ? [] : props.options,
      filter: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  getOptions = (inputValue) => {
    let count = 0;
    if (!this.props.remote) {
      const options = this.state.options.filter((option) => {
        const keep = (!inputValue ||
          option[this.props.field].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
          count < 10;
        if (keep) {
          count += 1;
        }
        return keep;
      });
      this.setState({ options, filter: inputValue });
    } else if (inputValue.length > this.props.minChar) {
      this.props.sourceOptions(inputValue).then((data) => {
        const options = [];
        data.forEach(it => options.push(it));
        this.setState({ options, filter: inputValue });
      });
    } else if (inputValue.length === 0) {
      this.setState({ options: [], filter: inputValue });
    }
  }

  renderInput = (inputProps) => {
    const {
      InputProps, classes, onClick, onMouseDown, value, ...other
    } = inputProps;
    return (
      <FormControl className="autocomplete-form-control">
        <InputLabel className="autocomplete-label" htmlFor={InputProps.id}>{InputProps.placeholder}</InputLabel>
        <Input
          id={InputProps.id}
          {...InputProps}
          value={InputProps.value}
          classes={
            { root: classes.inputRoot }
          }
          {...other}
          endAdornment={
            <InputAdornment position="end" className="full-height-width">
              <IconButton
                onClick={() => {
                  onClick();
                }}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }

  renderOption = (option, index, itemProps, highlightedIndex, selectedItem) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(option[this.props.field]) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={index}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {option[this.props.field]}
        { this.props.deleteItem &&
          <ListItemSecondaryAction>
            <IconButton
              className="full-height-width button-mt10"
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        }
      </MenuItem>
    );
  }

  render() {
    const {
      classes, style, placeholder, id, onClick, onMouseDown, onAddNew, loadingAddNew,
    } = this.props;
    let { options } = this.state;
    return (
      <div style={style}>
        <Downshift>
          {({
            getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex,
            clearSelection,
          }) => {
            if (inputValue.length > 0 && this.state.filter !== inputValue) {
              this.getOptions(inputValue);
            } else if (inputValue.length === 0) {
              options = [];
            }
            return (
              <div className={classes.container}>
                {this.renderInput({
                  classes,
                  onClick: () => {
                    const filterText = options.filter(option =>
                      selectedItem &&
                      option[this.props.field].toLowerCase() === selectedItem.toLowerCase())[0];
                    if (filterText) {
                      onClick(filterText);
                      clearSelection();
                    }
                  },
                  onMouseDown,
                  InputProps: getInputProps({
                    placeholder,
                    id,
                  }),
                })}
                { isOpen &&
                  <Paper className={classes.paper} square>
                    {
                      options.length > 0 ? options.map((option, index) => this.renderOption(
                        option,
                        index,
                        getItemProps({ item: option[this.props.field] }),
                        highlightedIndex,
                        selectedItem,
                      )) : null
                    }
                    { onAddNew &&
                      <MenuItem onClick={onAddNew(inputValue)}>
                        <ListItemText className="padding-left-0" primary="Agregar Nuevo..." />
                        <ListItemSecondaryAction>
                          { loadingAddNew &&
                            <CircularProgress className="circle-progress" />
                          }
                        </ListItemSecondaryAction>
                      </MenuItem>
                    }
                  </Paper>
                }
              </div>
            );
            }
          }
        </Downshift>
      </div>
    );
  }
}

AutoComplete.defaultProps = {
  classes: {
    container: 'autocomplete-container',
    paper: 'autocomplete-paper',
  },
  options: [],
  style: {},
  onAddNew: null,
  loadingAddNew: false,
  field: 'label',
  deleteItem: false,
  remote: false,
  minChar: 5,
};

export default AutoComplete;
