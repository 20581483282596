import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import TextField from '@material-ui/core/TextField';
import Subheader from '@material-ui/core/ListSubheader';
import { Image } from 'cloudinary-react';


class GridListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchModels: props.data.items,
    };
  }

  searchModel = (evt) => {
    const search = evt.target.value;
    setTimeout(() => {
      const { data } = this.props;
      const filters = data.items
        .filter(elem => elem.title.toLowerCase().startsWith(search.toLowerCase()) ||
          elem.code.toLowerCase().startsWith(search.toLowerCase()));
      if (search && search !== '') {
        this.setState({
          searchModels: filters,
        });
      } else {
        this.setState({
          searchModels: data.items,
        });
      }
    }, 500);
  }

  render() {
    const { data } = this.props;
    const { searchModels } = this.state;
    return (
      <div className="grid-list-component">
        <div className="header-content">
          <Subheader component="div" className="title-bar" >{data.title}</Subheader>
          <div className="search">
            <TextField label="Buscar modelo..." onKeyUp={this.searchModel} />
          </div>
        </div>
        <div className="container-grid-list">
          <ImageList rowHeight={200} cols={4}>
            {searchModels.map(tile => (
              <ImageListItem
                key={tile.img + Math.floor((Math.random() * 1000) + 1)}
                className={tile.code === '' ? '' : 'item-grid-list'}
                onClick={() => {
                  if (tile.uri !== '') {
                    this.props.location.href = this.props.location.href + tile.uri;
                  }
                }}
              >
                <Image
                  cloudName="enesoftec"
                  publicId={`${tile.img}.jpg`}
                  version={tile.version_img}
                  className="img-grid-list"
                  width="200"
                  height="200"
                  crop="scale"
                  alt={tile.title}
                  title={tile.title}
                />
                <ImageListItemBar
                  title={tile.title}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: window.location,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(GridListComponent);
