import React from 'react';

import AppBar from '@material-ui/core/AppBar';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Loading from './../components/loading';

import EneFirestore from './../../libs/ene/firestore';

const TabContainer = props => (
  <div className="tab-container">
    <Paper elevation={4} className="paper-container">{props.children}</Paper>
  </div>
);

export default class GeneralManagement extends React.Component {
  constructor(props) {
    super(props);
    this.eneFirestore = new EneFirestore();

    this.state = {
      loading: true,
      valueTab: 0,
      messageLoading: 'Cargando...',
      generals: [],
      milage: [],
      km: 0,
    };
  }
  componentWillMount() {
    this.fetchGroups();
    this.fetchMilage();
  }

  fetchGroups = () => {
    this.eneFirestore.get(['groups']).then((res) => {
      if (!res.empty) {
        const generals = [];
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          it.showList = it.showList !== undefined ? it.showList : false;
          generals.push(it);
        });
        this.setState({ generals, loading: false });
      }
    });
  }

  fetchMilage = () => {
    this.eneFirestore.get(['maintenance']).then((res) => {
      if (!res.empty) {
        const items = [];
        res.forEach((doc) => {
          const it = doc.data();
          it.code = doc.id;
          it.showList = it.showList !== undefined ? it.showList : false;
          items.push(it);
        });
        const milage = items.sort((a, b) => a.order - b.order);
        this.setState({ milage });
      }
    });
  }

  handleSwitchGroup = (key, value) => {
    this.eneFirestore.updateDocument(['groups', key], { showList: value })
      .then(() => {
        this.fetchGroups();
      });
  }

  handleSwitchMilage = (key, value) => {
    this.eneFirestore.updateDocument(['maintenance', key.toString()], { showList: value })
      .then(() => {
        this.fetchMilage();
      });
  }

  handleTabChange = (evt, valueTab) => {
    this.setState({ valueTab });
  };

  render() {
    const {
      loading, valueTab, messageLoading,
      generals, milage,
    } = this.state;

    if (loading) {
      return <Loading message={messageLoading} />;
    }

    return (
      <div style={{ width: '100%' }}>
        <AppBar
          position="static"
          className="app-bar"
        >
          <Tabs value={valueTab} className="tabs" onChange={this.handleTabChange}>
            <Tab label="Listado de Modelos" className="text-tab" />
            <Tab label="Kilometrajes" className="text-tab" />
          </Tabs>
        </AppBar>
        <div>
          <div className="container clearfix">
            { valueTab === 0 &&
              <TabContainer>
                <div className="item-tab-container">
                  <MenuList>
                    {
                      generals.map(item => (
                        <MenuItem key={item.code}>
                          <ListItemText className="padding-left-0 list-item-text" primary={item.name} inset />
                          <ListItemSecondaryAction>
                            <Switch
                              onChange={() => {
                                this.handleSwitchGroup(item.code, !item.showList);
                              }}
                              checked={item.showList}
                            />
                          </ListItemSecondaryAction>
                        </MenuItem>
                      ))
                    }
                  </MenuList>
                </div>
              </TabContainer>
            }
            {
              valueTab === 1 &&
              <TabContainer>
                <div className="item-tab-container">
                  <MenuList>
                    {
                      milage.map((item, index) => (
                        <MenuItem key={index}>
                          <ListItemText className="padding-left-0 list-item-text" primary={item.name} inset />
                          <ListItemSecondaryAction>
                            <Switch
                              onChange={() => {
                                this.handleSwitchMilage(item.code, !item.showList);
                              }}
                              checked={item.showList}
                            />
                          </ListItemSecondaryAction>
                        </MenuItem>
                      ))
                    }
                  </MenuList>
                </div>
              </TabContainer>
            }
          </div>
        </div>
      </div>
    );
  }
}
